import { useLocation, useNavigate } from 'react-router-dom';
import { ONBOARDING_ROUTE } from './AppRoutes';
import {
  DialogMode,
  openAddNewServiceCardDialogue,
  openConfigurationDialogue,
  openSidebar,
  setSelectedVehicle,
} from '../pages/onboarding/state/onboardingUiSlice';
import { PaymentPartnerType, Vehicle } from '../codegen/vehicles';
import { useVehicles } from '../state/vehicles/useVehicles';
import { useAppDispatch } from '../state/hooks';
import { VehicleWithGroups } from '../models';
import { useEffect } from 'react';

export const useCheckDeeplinking = () => {
  const { vehicles, isLoading } = useVehicles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const queryParams = new URLSearchParams(location.search);

  const PARAMETER_SHOW_CONFIGURATION_DIALOG = 'editDialog';
  const PARAMETER_SHOW_ADD_NEW_SERVICE_CARD_DIALOG = 'addNewCardDialog';
  const PARAMETER_EXTERNAL_LOGIN_ERROR = 'externalLoginError';

  const selectVehicle = (vehicle: Vehicle) => {
    dispatch(setSelectedVehicle(vehicle));
    dispatch(openSidebar());
  };

  const openConfigurationDialog = (dialogMode: DialogMode) => {
    dispatch(openConfigurationDialogue(dialogMode));
  };

  const openAddNewServiceCardDialog = (
    paymentPartnerType: PaymentPartnerType,
    externalLoginError: string,
  ) => {
    dispatch(
      openAddNewServiceCardDialogue({
        paymentPartnerType,
        externalLoginError,
      }),
    );
  };

  const getVehicle = (): VehicleWithGroups | undefined => {
    const vehicleId = queryParams.get('vehicleId');

    if (!vehicleId || vehicles.length < 1) {
      return undefined;
    }

    return vehicles.find((v) => v.id === vehicleId);
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (path === ONBOARDING_ROUTE) {
      const vehicle = getVehicle();
      if (vehicle) {
        selectVehicle(vehicle);

        const dialogValue = queryParams.get(
          PARAMETER_SHOW_CONFIGURATION_DIALOG,
        );
        if (dialogValue) {
          openConfigurationDialog(dialogValue as DialogMode);

          const showAddNewCardDialogValue = queryParams.get(
            PARAMETER_SHOW_ADD_NEW_SERVICE_CARD_DIALOG,
          );
          if (showAddNewCardDialogValue) {
            openAddNewServiceCardDialog(
              showAddNewCardDialogValue as PaymentPartnerType,
              queryParams.get(PARAMETER_EXTERNAL_LOGIN_ERROR) || '',
            );
          }
        }
        navigate(ONBOARDING_ROUTE);
        return;
      }
    }

    navigate(path);
  }, [isLoading]);
};

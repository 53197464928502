import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../state/store';
import {
  PaymentPartnerType,
  ServiceActivation,
  Vehicle,
} from '../../../codegen/vehicles';

export enum DialogMode {
  SERVICES = 'SERVICES',
  SERVICE_CARDS = 'SERVICE_CARDS',
}

export type AddNewServiceDialogPayload = {
  paymentPartnerType: PaymentPartnerType;
  externalLoginError?: string;
};

type RemoveConfirmationDialoguePayload = {
  linkedServiceActivations: ServiceActivation[];
  paymentMethodId: string;
};

type OnboardingState = {
  selectedVehicle?: Vehicle;
  sidebar: {
    open: boolean;
  };
  serviceConfigurationDialogue: {
    open: boolean;
    dialogMode: DialogMode;
  };
  addNewServiceCardDialogue: {
    open: boolean;
    selectedPaymentPartnerType: PaymentPartnerType;
    externalLoginError?: string;
  };
  removeConfirmationDialogue: {
    open: boolean;
    linkedServiceActivations: ServiceActivation[];
    paymentMethodId: string;
  };
};

const initialState: OnboardingState = {
  sidebar: {
    open: false,
  },
  serviceConfigurationDialogue: {
    open: false,
    dialogMode: DialogMode.SERVICES,
  },
  addNewServiceCardDialogue: {
    open: false,
    selectedPaymentPartnerType: PaymentPartnerType.MockFuelingPayment,
    externalLoginError: undefined,
  },
  removeConfirmationDialogue: {
    open: false,
    linkedServiceActivations: [],
    paymentMethodId: '',
  },
  selectedVehicle: undefined,
};

export const onboardingUiSlice = createSlice({
  name: 'onboardingPage',
  initialState,
  reducers: {
    setSelectedVehicle: (state, action: PayloadAction<Vehicle>) => {
      state.selectedVehicle = action.payload;
    },
    openSidebar: (state) => {
      if (state.selectedVehicle) {
        state.sidebar.open = true;
      }
    },
    closeSidebar: (state) => {
      state.sidebar.open = false;
    },
    openConfigurationDialogue: (state, action: PayloadAction<DialogMode>) => {
      if (!state.selectedVehicle) {
        return;
      }
      state.serviceConfigurationDialogue.dialogMode = action.payload;
      state.sidebar.open = false;
      state.serviceConfigurationDialogue.open = true;
    },
    closeConfigurationDialogue: (state) => {
      state.serviceConfigurationDialogue.open = false;
      state.selectedVehicle = undefined;
    },
    setDialogMode: (state, action: PayloadAction<DialogMode>) => {
      state.serviceConfigurationDialogue.dialogMode = action.payload;
    },
    openRemoveConfirmationDialogue: (
      state,
      action: PayloadAction<RemoveConfirmationDialoguePayload>,
    ) => {
      state.removeConfirmationDialogue.open = true;
      state.removeConfirmationDialogue.linkedServiceActivations =
        action.payload.linkedServiceActivations;
      state.removeConfirmationDialogue.paymentMethodId =
        action.payload.paymentMethodId;
    },
    closeRemoveConfirmationDialogue: (state) => {
      state.removeConfirmationDialogue.open = false;
      state.removeConfirmationDialogue.linkedServiceActivations = [];
    },
    openAddNewServiceCardDialogue: (
      state,
      action: PayloadAction<AddNewServiceDialogPayload>,
    ) => {
      state.addNewServiceCardDialogue.open = true;
      const addNewServiceDialogParameter = action.payload;
      state.addNewServiceCardDialogue.selectedPaymentPartnerType =
        addNewServiceDialogParameter.paymentPartnerType;
      state.addNewServiceCardDialogue.externalLoginError =
        addNewServiceDialogParameter.externalLoginError;
    },
    setPaymentPartnerType: (
      state,
      action: PayloadAction<PaymentPartnerType>,
    ) => {
      state.addNewServiceCardDialogue.selectedPaymentPartnerType =
        action.payload;
    },
    closeAddNewServiceCardDialogue: (state) => {
      state.addNewServiceCardDialogue.open = false;
    },
  },
});

export const {
  setSelectedVehicle,
  openSidebar,
  openConfigurationDialogue,
  closeConfigurationDialogue,
  setDialogMode,
  openRemoveConfirmationDialogue,
  closeRemoveConfirmationDialogue,
  closeSidebar,
  openAddNewServiceCardDialogue,
  setPaymentPartnerType,
  closeAddNewServiceCardDialogue,
} = onboardingUiSlice.actions;

export const getSelectedVehicle = (state: RootState) =>
  state.onboardingUiState.selectedVehicle;
export const isConfigurationDialogueOpen = (state: RootState) =>
  state.onboardingUiState.serviceConfigurationDialogue.open;
export const getDialogMode = (state: RootState) =>
  state.onboardingUiState.serviceConfigurationDialogue.dialogMode;
export const isAddNewServiceCardDialogueOpen = (state: RootState) =>
  state.onboardingUiState.addNewServiceCardDialogue.open;
export const addNewServiceCardDialogueError = (state: RootState) =>
  state.onboardingUiState.addNewServiceCardDialogue.externalLoginError;
export const isRemoveConfirmationDialogueOpen = (state: RootState) =>
  state.onboardingUiState.removeConfirmationDialogue.open;
export const getPaymentPartnerType = (state: RootState) =>
  state.onboardingUiState.addNewServiceCardDialogue.selectedPaymentPartnerType;
export const getPayloadRemoveConfirmationDialogue = (state: RootState) => {
  const { linkedServiceActivations, paymentMethodId } =
    state.onboardingUiState.removeConfirmationDialogue;
  return {
    linkedServiceActivations,
    paymentMethodId,
  };
};
export const isSidebarOpen = (state: RootState) =>
  state.onboardingUiState.sidebar.open;

import { GraphqlRequestType, Request } from './baseQuery';
import { parse } from 'graphql';

export const USED_VEHICLE_FIELDS = `
        id
        name
        vin
        type
        group_ids
        enabled
        brand
        services {
          payment_partners {
            type
            payment_methods {
              id
              type
              fuel_card_type
              last_four_digits
              expiry
              max_authorization_amount
              enabled
            }
          }
          service_partner_type
          service_type
        }
`;

export const listVehicles: Request = {
  type: GraphqlRequestType.QUERY,
  definition: parse(`
  query listVehicles {
    listVehicles {
      items {
        ${USED_VEHICLE_FIELDS}
      }
    }
  }
`),
};

export const listVehicleGroups: Request = {
  type: GraphqlRequestType.QUERY,
  definition: parse(`query listVehicleGroups {
    listVehicleGroups {
      items {
        id
        name
      }
    }
  }`),
};

export const USED_TRANSACTION_FIELDS = `
        id
        total_amount_gross
        total_amount_net
        currency
        items {
          description
          quantity
          unit
          item_price_gross
          sum_gross
          vat
        }
        service
        service_partner
        state
        created_at
        mileage
        vehicle {
          id
          group_ids
          name
          type
          vin
        }
        payment_details {
          paid_at
          payment_method
          payment_partner
        }
        fueling_details {
          fuel_station_name
          fuel_station_brand
          fuel_station_id
          fuel_pump_number
          fuel_station_address {
            street
            city
            zip_code
            state
            country
          }
        }
`;

export const listTransactions: Request = {
  type: GraphqlRequestType.QUERY,
  definition: parse(`query listTransactions(
      $page_size: Int,
      $page_number: Int,
      $filters: ListTransactionsFilters!,
    ){
      listTransactions(
        page_size: $page_size,
        page_number: $page_number,
        filters:  $filters,
      ) {
        items {
          ${USED_TRANSACTION_FIELDS}
        }
        total
      }
    }`),
};

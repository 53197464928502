import moment from 'moment/moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { useCustomIntl } from '../../i18n/i18n';
import { useAppSelector } from '../hooks';
import { useMemo } from 'react';
import { useTransactions } from './useTransactions';
import {
  ServiceType,
  Transaction,
  TransactionState,
} from '../../codegen/transactions';
import map from 'lodash/map';
import { SERVICE_TO_TRANSLATION_KEY } from '../../i18n/enumMappings';
import { VehicleWithGroups } from '../../models';
import { useVehicles } from '../vehicles/useVehicles';
import { getDatesForPeriod, Period } from '../../usePeriodSelectOptions';

// Dates are stored as numbers, because a Moment object is not serializable and therefore not storable in Redux.
type TransactionFilterState = {
  dashboardCurrency: string;
  transactionCurrencies: string[];
  from: number;
  to: number;
  period?: Period;
  vehicles: string[];
  vehicleGroups: string[];
  serviceTypes: ServiceType[];
  page: number;
};

const FIRST_PAGE = 1;
export const DEFAULT_CURRENCY = 'EUR';
const DEFAULT_PERIOD = Period.LAST_7_DAYS;

const LOCAL_STORAGE_DASHBOARD_CURRENCY_KEY = 'SELECTED_DASHBOARD_CURRENCY';
const LOCAL_STORAGE_TRANSACTIONS_PAGE_CURRENCIES_KEY =
  'SELECTED_TRANSACTIONS_PAGE_CURRENCIES';

const initialState: TransactionFilterState = {
  dashboardCurrency:
    localStorage.getItem(LOCAL_STORAGE_DASHBOARD_CURRENCY_KEY) ||
    DEFAULT_CURRENCY,
  transactionCurrencies: JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_TRANSACTIONS_PAGE_CURRENCIES_KEY) ||
      '[]',
  ),
  from: getDatesForPeriod(DEFAULT_PERIOD).from.valueOf(),
  to: getDatesForPeriod(DEFAULT_PERIOD).to.valueOf(),
  period: DEFAULT_PERIOD,
  vehicles: [],
  vehicleGroups: [],
  serviceTypes: [],
  page: FIRST_PAGE,
};

export const transactionFilterSlice = createSlice({
  name: 'transactionFilter',
  initialState,
  reducers: {
    setDashboardCurrency: (state, action: PayloadAction<string>) => {
      state.dashboardCurrency = action.payload;
      localStorage.setItem(
        LOCAL_STORAGE_DASHBOARD_CURRENCY_KEY,
        action.payload,
      );
    },
    setTransactionsCurrencies: (state, action: PayloadAction<string[]>) => {
      state.transactionCurrencies = action.payload;
      localStorage.setItem(
        LOCAL_STORAGE_TRANSACTIONS_PAGE_CURRENCIES_KEY,
        JSON.stringify(action.payload),
      );

      state.page = FIRST_PAGE;
    },
    setFrom: (state, action: PayloadAction<number>) => {
      state.from = moment(action.payload).startOf('day').valueOf();
      state.period = undefined;

      state.page = FIRST_PAGE;
    },
    setTo: (state, action: PayloadAction<number>) => {
      state.to = moment(action.payload).endOf('day').valueOf();
      state.period = undefined;

      state.page = FIRST_PAGE;
    },
    setPeriod: (state, action: PayloadAction<Period | undefined>) => {
      const period = action.payload;
      state.period = period;

      if (period) {
        const dates = getDatesForPeriod(period);
        state.from = dates.from.startOf('day').valueOf();
        state.to = dates.to.endOf('day').valueOf();

        state.page = FIRST_PAGE;
      }
    },
    resetTransactionsFilters: (state) => {
      state.transactionCurrencies = [];
      state.from = initialState.from;
      state.to = initialState.to;
      state.period = initialState.period;
      state.vehicles = initialState.vehicles;
      state.vehicleGroups = initialState.vehicleGroups;
      state.serviceTypes = initialState.serviceTypes;

      state.page = FIRST_PAGE;
    },
    setVehicles: (state, action: PayloadAction<string[]>) => {
      state.vehicles = action.payload;

      state.page = FIRST_PAGE;
    },
    setVehicleGroups: (state, action: PayloadAction<string[]>) => {
      state.vehicleGroups = action.payload;

      state.page = FIRST_PAGE;
    },
    setServiceTypes: (state, action: PayloadAction<ServiceType[]>) => {
      state.serviceTypes = action.payload;

      state.page = FIRST_PAGE;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const {
  setDashboardCurrency,
  setTransactionsCurrencies,
  setFrom,
  setTo,
  setPeriod,
  setVehicles,
  setVehicleGroups,
  setServiceTypes,
  setPage,
  resetTransactionsFilters,
} = transactionFilterSlice.actions;

export const getDashboardCurrencyFilter = (state: RootState) =>
  state.transactionFilter.dashboardCurrency;
export const getTransactionCurrenciesFilter = (state: RootState) =>
  state.transactionFilter.transactionCurrencies;
export const getFromFilter = (state: RootState) => state.transactionFilter.from;
export const getToFilter = (state: RootState) => state.transactionFilter.to;
export const getPeriodFilter = (state: RootState) =>
  state.transactionFilter.period;
export const getVehiclesFilter = (state: RootState) =>
  state.transactionFilter.vehicles;
export const getVehicleGroupsFilter = (state: RootState) =>
  state.transactionFilter.vehicleGroups;
export const getServiceTypesFilter = (state: RootState) =>
  state.transactionFilter.serviceTypes;
export const getTransactionPage = (state: RootState) =>
  state.transactionFilter.page;

export const useDashboardFilteredTransactions = () => {
  const intl = useCustomIntl();
  const dashboardCurrency = useAppSelector(getDashboardCurrencyFilter);
  const serviceTypes = useAppSelector(getServiceTypesFilter);
  const from = useAppSelector(getFromFilter);
  const to = useAppSelector(getToFilter);
  const { transactions: transactionsForServiceType } = useTransactions({
    currency: [dashboardCurrency],
    fromCreatedAt: moment(from).format(),
    toCreatedAt: moment(to).format(),
  });

  const { transactions, isLoading, error, resetApiState } = useTransactions({
    currency: [dashboardCurrency],
    serviceType: serviceTypes,
    fromCreatedAt: moment(from).format(),
    toCreatedAt: moment(to).format(),
  });

  const filteredTransactions = useMemo(() => {
    const excludedStates = [
      TransactionState.Created,
      TransactionState.InProgress,
      TransactionState.Failed,
      TransactionState.Canceled,
    ];

    return transactions.filter((t) => !excludedStates.includes(t.state));
  }, [transactions, dashboardCurrency]);

  const serviceTypeOptions = map(SERVICE_TO_TRANSLATION_KEY, (value, key) => {
    const serviceTypesInTransactions: ServiceType[] = Array.from(
      new Set(transactionsForServiceType.map((item) => item.serviceType)),
    );
    return {
      id: key,
      label: intl.formatMessage({ id: value }),
      selected: serviceTypes.includes(key as ServiceType),
      disabled: !serviceTypesInTransactions.includes(key as ServiceType),
    };
  });

  return {
    filteredTransactions,
    isLoading,
    error,
    serviceTypeOptions,
    resetApiState,
  };
};

export const useDashboardFilteredVehicles = () => {
  const {
    filteredTransactions,
    isLoading: isLoadingTransactions,
    error: errorLoadingTransactions,
  } = useDashboardFilteredTransactions();

  const {
    vehicles,
    isLoading: isLoadingVehicles,
    error: errorLoadingVehicles,
  } = useVehicles();

  const vehiclesWithSucceededTransactions = useMemo<VehicleWithGroups[]>(() => {
    const vehicleIdsWithSucceededTransactions: string[] = Array.from(
      new Set(
        filteredTransactions.map(
          (transaction: Transaction) => transaction.vehicleId,
        ),
      ),
    );
    return vehicles.filter((vehicle: VehicleWithGroups) =>
      vehicleIdsWithSucceededTransactions.includes(vehicle.id),
    );
  }, [filteredTransactions, vehicles]);

  return {
    vehicles: vehiclesWithSucceededTransactions,
    isLoading: isLoadingTransactions || isLoadingVehicles,
    error: errorLoadingTransactions || errorLoadingVehicles,
  };
};

export const TRANSACTION_TABLE_PAGE_SIZE = 50;

export const useTableFilteredTransactions = () => {
  const transactionCurrencies = useAppSelector(getTransactionCurrenciesFilter);
  const serviceTypes = useAppSelector(getServiceTypesFilter);
  const from = useAppSelector(getFromFilter);
  const to = useAppSelector(getToFilter);
  const vehicles = useAppSelector(getVehiclesFilter);
  const vehicleGroups = useAppSelector(getVehicleGroupsFilter);
  const page = useAppSelector(getTransactionPage);

  const {
    transactions,
    totalTransactions,
    isLoading,
    isFetching,
    error,
    resetApiState,
  } = useTransactions({
    pageSize: TRANSACTION_TABLE_PAGE_SIZE,
    pageNumber: page - 1,
    vehicleId: vehicles.length !== 0 ? vehicles : undefined,
    vehicleGroupId: vehicleGroups.length !== 0 ? vehicleGroups : undefined,
    fromCreatedAt: moment(from).format(),
    toCreatedAt: moment(to).format(),
    currency:
      transactionCurrencies.length !== 0 ? transactionCurrencies : undefined,
    serviceType: serviceTypes,
  });

  return {
    filteredTransactions: transactions,
    isLoading,
    isFetching,
    error,
    totalTransactions,
    resetApiState,
  };
};

import React from 'react';
import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import {
  getFromFilter,
  getPeriodFilter,
  getToFilter,
  resetBookingsFilter,
  setFrom,
  setPeriod,
  setTo,
} from '../../../../state/bookings/bookingsFilterSlice';
import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import {
  parsePeriod,
  usePeriodSelectOptions,
} from '../../../../usePeriodSelectOptions';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { Moment } from 'moment';

export const BookingsTableToolbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const periodSelectOptions = usePeriodSelectOptions();

  const from = useAppSelector(getFromFilter);
  const to = useAppSelector(getToFilter);
  const period = useAppSelector(getPeriodFilter);

  return (
    <TableToolbar>
      <div
        className="table-toolbar-container"
        data-testid={'bookings-table-toolbar'}
      >
        <div className="table-toolbar-group-left">
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbat">
              <div data-testid={'bookings-table-start-date-picker'}>
                <label>
                  <CustomFormattedMessage id={'common.startDate'} />
                </label>
                <DatePicker
                  closeOnSelect
                  timeFormat={false}
                  value={from}
                  minWidth={130}
                  onChange={(value: Moment, valid: boolean) => {
                    if (valid) {
                      const millis = value.valueOf();
                      dispatch(setFrom(millis));
                    }
                  }}
                  dateFormat={'DD/MM/YYYY'}
                />
              </div>
            </div>
          </div>
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <div data-testid={'bookings-table-end-date-picker'}>
                <label>
                  <CustomFormattedMessage id={'common.endDate'} />
                </label>
                <DatePicker
                  aria-label={'end-date'}
                  closeOnSelect
                  timeFormat={false}
                  value={to}
                  minWidth={130}
                  onChange={(value: Moment, valid: boolean) => {
                    if (valid) {
                      const millis = value.valueOf();
                      dispatch(setTo(millis));
                    }
                  }}
                  dateFormat={'DD/MM/YYYY'}
                />
              </div>
            </div>
          </div>
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <div data-testid={'bookings-table-period-selector'}>
                <label>
                  <CustomFormattedMessage id={'common.period'} />
                </label>
                <Select
                  useClear
                  className={'width-200'}
                  id={'periodSelector'}
                  placeholder={
                    <CustomFormattedMessage id={'common.selectPeriod'} />
                  }
                  options={periodSelectOptions}
                  onChange={(option) => {
                    const value = option ? parsePeriod(option.id) : undefined;
                    dispatch(setPeriod(value));
                  }}
                  value={period ? [period.toString()] : []}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-toolbar-group-right">
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <Button
                bsStyle={'primary'}
                onClick={() => {
                  dispatch(resetBookingsFilter());
                }}
              >
                <span className={'rioglyph rioglyph-revert'} />
                <CustomFormattedMessage
                  id={'transactions.transactionsTable.toolbar.resetFilters'}
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </TableToolbar>
  );
};

import React from 'react';
import manCardIcon from '../../assets/images/fuelCardTypes/man-card-logo.svg';
import utaCardIcon from '../../assets/images/fuelCardTypes/uta_card_logo.svg';
import { PaymentPartnerType } from '../../codegen/vehicles';

type ServiceCardTypeIconProps = {
  type: PaymentPartnerType;
  className: string;
};

const serviceCardTypesToIcon: { [key in PaymentPartnerType]: string } = {
  UTA_PAYMENT: utaCardIcon,
  MOCK_FUELING_PAYMENT: manCardIcon,
};

export const ServiceCardTypeIcon: React.FC<ServiceCardTypeIconProps> = ({
  type,
  className,
}) => (
  <img
    alt={'logo'}
    src={serviceCardTypesToIcon[type]}
    className={className}
  />
);

import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import React, { useEffect, useMemo } from 'react';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { ActiveServicesTable } from './activeServiceTable/ActiveServicesTable';
import { NoServices } from './NoServices';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import {
  closeSidebar,
  DialogMode,
  getSelectedVehicle,
  isSidebarOpen,
  openConfigurationDialogue,
} from '../../state/onboardingUiSlice';
import { VehicleHeadline } from '../VehicleHeadline';
import { useLocation } from 'react-router-dom';
import { ONBOARDING_ROUTE } from '../../../../routes/AppRoutes';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { ServiceActivation, ServiceType } from '../../../../codegen/vehicles';
import isEmpty from 'lodash/isEmpty';

export const SIDEBAR_LABEL_COLOR = 'text-color-gray';
export const SIDEBAR_DATA_COLOR = 'text-color-dark';

export const VehicleSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isSidebarOpen);
  const vehicle = useAppSelector(getSelectedVehicle);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== ONBOARDING_ROUTE) {
      dispatch(closeSidebar());
    }
  }, [location.pathname]);

  const services = useMemo(() => {
    const activeServices =
      vehicle?.serviceActivations.filter((service) => service.enabled) || [];

    const servicesMap: {
      [key in ServiceType]: ServiceActivation | undefined;
    } = {
      FUELING: undefined,
      BREAKDOWN: undefined,
      PARKING: undefined,
      WASHING: undefined,
    };

    for (const service of activeServices) {
      servicesMap[service.serviceType] = service;
    }

    return Object.values(servicesMap).filter((s) => !!s) as ServiceActivation[];
  }, [vehicle?.serviceActivations]);

  const handleClose = () => {
    dispatch(closeSidebar());
  };

  const onAssignServices = () => {
    const hasPaymentMethods = !isEmpty(vehicle?.paymentMethods);
    dispatch(
      openConfigurationDialogue(
        hasPaymentMethods ? DialogMode.SERVICES : DialogMode.SERVICE_CARDS,
      ),
    );
  };

  if (!isOpen || !vehicle) {
    return <></>;
  }

  return (
    <ApplicationLayout.Sidebar
      className={'right'}
      data-testid={'onboarding-vehicle-sidebar'}
    >
      <Sidebar
        title={<CustomFormattedMessage id={'onboarding.sidebar.title'} />}
        titleClassName={
          'padding-left-10 text-light text-uppercase text-size-16'
        }
        onClose={handleClose}
        resizable
        position={'right'}
        width={400}
        minWidth={330}
        maxWidth={600}
      >
        <div className={'padding-left-20 padding-right-20'}>
          <div className={'display-grid grid-cols-3'}>
            <div className={'grid-colspan-2'}>
              <VehicleHeadline vehicle={vehicle} />
            </div>
            <div className={'grid-colspan-1 text-size-20'}>
              <Button
                bsStyle={'primary'}
                type={'button'}
                onClick={onAssignServices}
                className={'pull-right margin-top-10'}
              >
                <span className={'rioglyph rioglyph-pencil'} />
                <CustomFormattedMessage id="onboarding.sidebar.body.editServices" />
              </Button>
            </div>
          </div>

          {services.length > 0 ? (
            <ActiveServicesTable
              activeServices={services}
              paymentMethods={vehicle.paymentMethods}
            />
          ) : (
            <NoServices />
          )}
        </div>
      </Sidebar>
    </ApplicationLayout.Sidebar>
  );
};

import React from 'react';
import { VehicleIcon } from '../../../../components/icons/VehicleIcon';
import { VehicleWithGroups } from '../../../../models';

type TransactionHeadlineProps = {
  vehicleWithGroups: VehicleWithGroups;
};

export const TransactionHeadline: React.FC<TransactionHeadlineProps> = ({
  vehicleWithGroups,
}) => {
  return (
    <>
      <h5>
        <span className="text-color-dark white-space-nowrap">
          <VehicleIcon
            type={vehicleWithGroups.type}
            className="margin-right-5"
          />
          <span className="margin-right-5">{vehicleWithGroups.name}</span>
        </span>
      </h5>
    </>
  );
};

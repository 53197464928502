import React from 'react';
import fuelingLogo from '../../assets/images/partners/fueling-placeholder.png';
import utaLogo from '../../assets/images/partners/uta-edenred-logo.png';
import { PaymentPartnerType } from '../../codegen/vehicles';

type PaymentPartnerIconProps = {
  paymentPartner: PaymentPartnerType;
  className?: string;
};

const paymentPartnersToImage: { [key in PaymentPartnerType]: string } = {
  UTA_PAYMENT: utaLogo,
  MOCK_FUELING_PAYMENT: fuelingLogo,
};

export const PaymentPartnerIcon: React.FC<PaymentPartnerIconProps> = ({
  paymentPartner,
  className,
}) => (
  <img
    alt={'logo'}
    src={paymentPartnersToImage[paymentPartner]}
    className={className}
    data-testid={`base-paymentPartner-icon-${paymentPartner}`}
  />
);

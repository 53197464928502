import { CustomFormattedMessage } from '../../i18n/i18n';
import { SERVICE_PARTNER_TO_TRANSLATION_KEY } from '../../i18n/enumMappings';
import { ServicePartnerIcon } from '../icons/ServicePartnerIcon';
import { ServicePartner } from '../../codegen/vehicles';

type ServicePartnerRepresentationProps = {
  servicePartner: ServicePartner;
};

export const ServicePartnerRepresentation = ({
  servicePartner,
}: ServicePartnerRepresentationProps) => {
  return (
    <>
      <ServicePartnerIcon
        servicePartner={servicePartner}
        className={'margin-right-5 height-20'}
      />
      <CustomFormattedMessage
        id={SERVICE_PARTNER_TO_TRANSLATION_KEY[servicePartner]}
      />
    </>
  );
};

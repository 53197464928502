import React from 'react';
import { CustomFormattedMessage, useCustomIntl } from '../../../i18n/i18n';

export const RioSupportInformation: React.FC = () => {
  const intl = useCustomIntl();

  const openManContactForm = () => {
    const contactUrl = `https://contact-form.rio.cloud/contact?locale=${intl.locale}`;
    const windowWith = 800;
    const windowHeight = 600;
    open(
      contactUrl,
      '_blank',
      `resizeable=yes,width=${windowWith},height=${windowHeight}`,
    );
  };

  return (
    <div className="callout callout-default">
      <div className=" text-medium text-size-20 line-height-125rel margin-bottom-10">
        MAN Rio
      </div>
      <ul className="tree-list margin-bottom-0">
        <li className="margin-bottom-0">
          <span className="icon rioglyph rioglyph rioglyph-earphone margin-right-5" />
          <span>
            Support Hotline: <a href="tel:+800 63444825">+800 63444825</a>
          </span>
        </li>
        <li className="margin-bottom-0">
          <span className="icon rioglyph rioglyph rioglyph-send margin-right-5" />
          <a onClick={openManContactForm}>
            <CustomFormattedMessage
              id={'base.body.supportDialog.openContactForm'}
            />
          </a>
        </li>
      </ul>
    </div>
  );
};

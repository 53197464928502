import { REDIRECT_FROM_AUTH_SERVER_ROUTE } from '../../routes/AppRoutes';
import { edenredUserManager } from './edenredUserManager';
import { REDIRECT_SOURCE_EDENRED } from '../../pages/RedirectPage';
import { routeStorage } from '../../routes/routeStorage';
import { BackendErrors } from '../../models';

export const handleExternalLogin = async (): Promise<void> => {
  if (window.location.pathname !== REDIRECT_FROM_AUTH_SERVER_ROUTE) {
    return;
  }

  const query = window.location.search;

  if (query.includes(`source=${REDIRECT_SOURCE_EDENRED}`)) {
    await edenredUserManager
      .signinRedirectCallback()
      .then((user) => {
        routeStorage.clearExternalLoginError();
        console.info('External login in:', user);
      })
      .catch((error) => {
        routeStorage.saveExternalLoginError(BackendErrors.EXTERNAL_LOGIN_ERROR);
        console.error('Error handling sign-in callback:', error);
      });
  }
};

import {
  ListBookingsApiArg,
  useListBookingsQuery,
} from '../../codegen/bookings';
import { useAppDispatch } from '../hooks';
import { bookingsApiSlice } from './bookingsSlice';

export const useBookings = (request: ListBookingsApiArg) => {
  const dispatch = useAppDispatch();
  const bookingsQuery = useListBookingsQuery(request);

  const refetchBookings = () => {
    bookingsQuery.refetch();
  };

  const resetApiState = () => {
    dispatch(bookingsApiSlice.util.resetApiState);
  };

  return {
    bookings: bookingsQuery.data?.items || [],
    totalBookings: bookingsQuery.data?.totalItems || 0,
    isLoading: bookingsQuery.isLoading,
    isFetching: bookingsQuery.isFetching,
    error: bookingsQuery.error,
    refetchBookings,
    resetApiState,
  };
};

import { TransactionState } from '../../../../codegen/transactions';
import {
  PaymentMethodType,
  ServiceType,
  Transaction,
  VehicleType,
} from '../../../../codegen/vehicles';
import { TransactionWithVehicle, VehicleWithGroups } from '../../../../models';
import { RowData } from '../../../../components/table/Table';

export type TransactionRow = {
  vehicleName?: string;
  vehicleType?: VehicleType;
  vehicleGroups: string[];
  service: ServiceType;
  createdAt: string;
  paymentMethod: PaymentMethodType;
  totalPriceGross?: number;
  status: TransactionState;
  currency?: string;
};

const mapTransactionToRow = (
  t: Transaction,
  v: VehicleWithGroups | undefined,
): RowData<TransactionRow> => ({
  key: t.id,
  enabled: true,
  vehicleName: v?.name,
  vehicleType: v?.type,
  vehicleGroups: v?.groups.map((g) => g.name) || [],
  service: t.serviceType,
  createdAt: t.createdAt,
  paymentMethod: t.paymentDetails.paymentMethod,
  totalPriceGross: parseFloat(t.totalAmountGross || '0'),
  status: t.state,
  currency: t.currency,
});

export const mapTransactionsToRows = (
  transactions: Transaction[],
  vehicles: VehicleWithGroups[],
  transactionsVehicleMap: Map<string, TransactionWithVehicle>,
): RowData<TransactionRow>[] => {
  return transactions.map((transaction: Transaction) => {
    const vehicleWithGroups = vehicles.find(
      (v) => v.id === transaction.vehicleId,
    );
    transactionsVehicleMap.set(transaction.id, {
      transaction,
      vehicleWithGroups,
    });
    return mapTransactionToRow(transaction, vehicleWithGroups);
  });
};

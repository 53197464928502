import { createApi } from '@reduxjs/toolkit/query/react';
import { restBaseQuery } from '../../configuration/baseQuery';

export const bookingsApiSlice = createApi({
  reducerPath: 'bookingsApi',
  baseQuery: restBaseQuery(),
  endpoints: () => ({}),
});

export const {
  reducer: bookingsReducer,
  reducerPath: bookingsReducerPath,
  middleware: bookingsMiddleware,
} = bookingsApiSlice;

export const appConfiguration = {
  login: {
    authority: import.meta.env.VITE_LOGIN_AUTHORITY!,
    clientId: import.meta.env.VITE_LOGIN_CLIENT_ID!,
    redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI!,
    mock: import.meta.env.VITE_LOGIN_MOCK_LOGIN,
    requiredScopes: import.meta.env.VITE_LOGIN_REQUIRED_SCOPES,
  },
  appSync: {
    url: import.meta.env.VITE_APPSYNC_URL!,
    region: import.meta.env.VITE_APPSYNC_REGION!,
  },
  rest: {
    url: import.meta.env.VITE_REST_URL!,
  },
  mock: {
    transactionUpdate:
      (import.meta.env.VITE_MOCK_TRANSACTION_UPDATE ?? 'false') === 'true',
  },
  paymentCardProviderLogin: {
    edenred: {
      authority: import.meta.env.VITE_EDENRED_LOGIN_AUTHORITY,
      clientId: import.meta.env.VITE_EDENRED_LOGIN_CLIENT_ID,
      clientSecret: import.meta.env.VITE_EDENRED_LOGIN_CLIENT_SECRET,
      redirectUri: import.meta.env.VITE_EDENRED_LOGIN_REDIRECT_URI,
      scopes: import.meta.env.VITE_EDENRED_LOGIN_SCOPES,
      acr_values: import.meta.env.VITE_EDENRED_LOGIN_ACR_VALUES,
      mock: import.meta.env.VITE_EDENRED_LOGIN_MOCK === 'true',
    },
  },
  supportedPaymentPartnerTypes:
    import.meta.env.VITE_SUPPORTED_PAYMENT_PARTNER_TYPES.split(','),
};

import { useFormContext } from 'react-hook-form';
import {
  PaymentMethod,
  PaymentMethodType,
  PaymentPartnerType,
  ServiceActivation,
  ServiceType,
  Vehicle,
} from '../../../../codegen/vehicles';
import { remove } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useAppSelector } from '../../../../state/hooks';
import { getPaymentPartnerType } from '../../state/onboardingUiSlice';

export type ServiceConfigurationForm = {
  serviceActivations: {
    [serviceType: string]: ServiceActivation;
  };
  paymentMethods: PaymentMethod[];
  newServiceCard: {
    type: PaymentMethodType;
    paymentPartner: PaymentPartnerType;
    cardNumber: string;
    expiryDate: string;
  };
};

export const createVehicleDialogueFormData = (vehicle?: Vehicle) => {
  const formData: ServiceConfigurationForm = {
    serviceActivations: {},
    paymentMethods: [],
    newServiceCard: {
      type: PaymentMethodType.FuelCard,
      paymentPartner: PaymentPartnerType.MockFuelingPayment,
      cardNumber: '',
      expiryDate: '',
    },
  };
  vehicle?.serviceActivations.forEach((s) => {
    if (!formData.serviceActivations[s.serviceType]) {
      formData.serviceActivations[s.serviceType] = s;
    }
  });
  formData.paymentMethods = vehicle?.paymentMethods || [];
  return formData;
};

export const useVehicleDialogueFormContext = () => {
  const form = useFormContext<ServiceConfigurationForm>();
  const selectedPaymentPartnerType = useAppSelector(getPaymentPartnerType);
  if (
    selectedPaymentPartnerType !==
    form.getValues()?.newServiceCard?.paymentPartner
  ) {
    form.setValue('newServiceCard.paymentPartner', selectedPaymentPartnerType);
  }

  const isServiceEnabled = (serviceType: ServiceType) => {
    return form.watch(`serviceActivations.${serviceType}.enabled`);
  };

  const getServiceActivations = () => {
    return form.getValues('serviceActivations');
  };

  const getServiceActivation = (serviceType: ServiceType) => {
    return form.getValues(`serviceActivations.${serviceType}`);
  };

  const setServiceActivationActive = (
    serviceType: ServiceType,
    enabled: boolean,
  ) => {
    form.setValue(`serviceActivations.${serviceType}.enabled`, enabled);
  };

  const assignPaymentMethodToServiceActivation = (
    serviceType: ServiceType,
    paymentMethodId: string,
  ) => {
    const value = isEmpty(paymentMethodId) ? [] : [{ paymentMethodId }];
    const serviceActivation = form.getValues(
      `serviceActivations.${serviceType}`,
    );
    form.setValue(`serviceActivations.${serviceType}`, {
      ...serviceActivation,
      paymentMethodAssignments: value,
    } as ServiceActivation);
  };

  const getNewServiceCard = () => {
    return form.getValues('newServiceCard');
  };

  const getPaymentMethods = () => {
    return form.getValues('paymentMethods');
  };

  const setPaymentMethods = (paymentMethods: PaymentMethod[]) => {
    return form.setValue('paymentMethods', paymentMethods);
  };

  const removePaymentMethod = (paymentMethodId: string) => {
    const paymentMethods = form.getValues('paymentMethods');
    remove(paymentMethods, { id: paymentMethodId });
    form.setValue('paymentMethods', paymentMethods);
  };

  return {
    ...form,
    getServiceActivations,
    getServiceActivation,
    setServiceActivationActive,
    getNewServiceCard,
    isServiceEnabled,
    assignPaymentMethodToServiceActivation,
    getPaymentMethods,
    setPaymentMethods,
    removePaymentMethod,
  };
};

import { useCustomIntl } from './i18n/i18n';
import moment from 'moment';

export enum Period {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_60_DAYS = 'LAST_60_DAYS',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
}

export const parsePeriod = (value: string) => {
  return Period[value as keyof typeof Period];
};

export const usePeriodSelectOptions = () => {
  const intl = useCustomIntl();

  const periods = Object.keys(Period);

  const periodLabels = {
    [Period.TODAY]: intl.formatMessage({ id: 'common.timerange.today' }),
    [Period.YESTERDAY]: intl.formatMessage({
      id: 'common.timerange.yesterday',
    }),
    [Period.LAST_7_DAYS]: intl.formatMessage(
      { id: 'common.timerange.lastXDays' },
      { quantity: 7 },
    ),
    [Period.LAST_30_DAYS]: intl.formatMessage(
      { id: 'common.timerange.lastXDays' },
      { quantity: 30 },
    ),
    [Period.LAST_60_DAYS]: intl.formatMessage(
      { id: 'common.timerange.lastXDays' },
      { quantity: 60 },
    ),
    [Period.LAST_WEEK]: intl.formatMessage({
      id: 'common.timerange.previousWeek',
    }),
    [Period.LAST_MONTH]: intl.formatMessage({
      id: 'common.timerange.previousMonth',
    }),
  };

  return periods.map((p) => ({
    id: p.toString(),
    label: periodLabels[parsePeriod(p)],
  }));
};

export const getDatesForPeriod = (period: Period) => {
  switch (period) {
    case Period.TODAY:
      return {
        from: moment().startOf('day'),
        to: moment().endOf('day'),
      };
    case Period.YESTERDAY:
      return {
        from: moment().subtract(1, 'days').startOf('day'),
        to: moment().subtract(1, 'days').endOf('day'),
      };
    case Period.LAST_7_DAYS:
      return {
        from: moment().subtract(6, 'days').startOf('day'),
        to: moment().endOf('day'),
      };
    case Period.LAST_30_DAYS:
      return {
        from: moment().subtract(29, 'days').startOf('day'),
        to: moment().endOf('day'),
      };
    case Period.LAST_60_DAYS:
      return {
        from: moment().subtract(59, 'days').startOf('day'),
        to: moment().endOf('day'),
      };
    case Period.LAST_WEEK:
      return {
        from: moment().subtract(1, 'week').startOf('isoWeek').startOf('day'),
        to: moment().subtract(1, 'week').endOf('isoWeek').endOf('day'),
      };
    case Period.LAST_MONTH:
      return {
        from: moment().subtract(1, 'month').startOf('month').startOf('day'),
        to: moment().subtract(1, 'month').endOf('month').endOf('day'),
      };
  }
};

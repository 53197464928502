import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { OnboardingPage } from '../pages/onboarding/OnboardingPage';
import { RedirectPage } from '../pages/RedirectPage';
import { DashboardPage } from '../pages/dashboard/DashboardPage';
import { TransactionPage } from '../pages/transactions/TransactionsPage';
import { BookingsPage } from '../pages/bookings/BookingsPage';

export const ONBOARDING_ROUTE = '/onboarding';
export const DASHBOARD_ROUTE = '/dashboard';
export const TRANSACTIONS_ROUTE = '/transactions';
export const BOOKINGS_ROUTE = '/bookings';
export const REDIRECT_FROM_AUTH_SERVER_ROUTE = '/redirect';

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path={ONBOARDING_ROUTE}
        element={<OnboardingPage />}
      />
      <Route
        path={DASHBOARD_ROUTE}
        element={<DashboardPage />}
      />
      <Route
        path={TRANSACTIONS_ROUTE}
        element={<TransactionPage />}
      />
      <Route
        path={BOOKINGS_ROUTE}
        element={<BookingsPage />}
      />
      <Route
        path={REDIRECT_FROM_AUTH_SERVER_ROUTE}
        element={<RedirectPage />}
      />
      <Route
        path="*"
        element={
          <Navigate
            replace
            to={ONBOARDING_ROUTE}
          />
        }
      />
    </Routes>
  );
};

import { RowData } from '../../../../components/table/Table';
import {
  Booking,
  ServiceType,
  VehicleType,
} from '../../../../codegen/vehicles';
import { VehicleWithGroups } from '../../../../models';
import { BookingStatus } from '../../../../codegen/bookings';

export type BookingRow = {
  vehicleName?: string;
  vehicleType?: VehicleType;
  vehicleGroups: string[];
  service: ServiceType;
  arrival: string;
  estimatedTotalPrice: number;
  currency: string;
  status: BookingStatus;
  driverName: string;
};

const mapBookingToRow = (
  b: Booking,
  v: VehicleWithGroups | undefined,
): RowData<BookingRow> => ({
  key: b.id,
  enabled: true,
  vehicleName: v?.name,
  vehicleType: v?.type,
  vehicleGroups: v?.groups.map((g) => g.name) ?? [],
  service: b.serviceType,
  arrival: b.arrivalTime,
  estimatedTotalPrice: parseFloat(b.estimatedPrice.value),
  currency: b.estimatedPrice.currency,
  status: b.status,
  driverName: b.driverName,
});

export const mapBookingsToRows = (
  bookings: Booking[],
  vehicles: VehicleWithGroups[],
): RowData<BookingRow>[] => {
  return bookings.map((booking: Booking) => {
    const vehicleWithGroups = vehicles.find(
      (v) => v.id === booking.vehicleInfo.vehicleId,
    );
    return mapBookingToRow(booking, vehicleWithGroups);
  });
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { TransactionResponse } from './dto';
import { restBaseQuery } from '../../configuration/baseQuery';
import { Transaction } from '../../codegen/transactions';

export const toTransaction = (dto: TransactionResponse): Transaction => {
  return {
    id: dto.id,
    totalAmountGross: dto.total_amount_gross,
    totalAmountNet: dto.total_amount_net,
    mileage: dto.mileage,
    paymentDetails: {
      paymentMethod: dto.payment_details.payment_method,
      paymentPartner: dto.payment_details.payment_partner,
      paidAt: dto.payment_details.paid_at!,
    },
    currency: dto.currency,
    serviceType: dto.service,
    servicePartner: dto.service_partner,
    poi: {
      id: dto.fueling_details!.fuel_station_id,
      name: dto.fueling_details!.fuel_station_name,
      address: {
        street: dto.fueling_details!.fuel_station_address.street,
        city: dto.fueling_details!.fuel_station_address.city,
        zipCode: dto.fueling_details!.fuel_station_address.zip_code,
        state: dto.fueling_details!.fuel_station_address.state,
        country: dto.fueling_details!.fuel_station_address.country,
      },
      brand: dto.fueling_details!.fuel_station_brand,
    },
    pumpNumber: parseFloat(dto.fueling_details!.fuel_pump_number),
    items: dto.items.map((response) => ({
      description: response.description,
      quantity: response.quantity,
      unit: response.unit,
      itemPriceGross: response.item_price_gross,
      itemPriceNet: '',
      sumGross: response.sum_gross,
      sumNet: '',
      vat: response.vat.toString(),
    })),
    state: dto.state,
    createdAt: dto.created_at,
    updatedAt: dto.updated_at,
    vehicleId: dto.vehicle.id,
  };
};

export const transactionsApiSlice = createApi({
  reducerPath: 'transactionsApi',
  baseQuery: restBaseQuery(),
  endpoints: () => ({}),
});

export const {
  reducer: transactionsReducer,
  reducerPath: transactionsReducerPath,
  middleware: transactionsMiddleware,
} = transactionsApiSlice;

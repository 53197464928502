import {
  enhancedApi,
  useGetVehiclesQuery,
  Vehicle,
} from '../../codegen/vehicles';
import { getUser } from '../../login/loginSlice';
import { mergeVehicleWithGroups, VehicleWithGroups } from '../../models';
import { useAppSelector } from '../hooks';
import { useGetVehicleGroupsQuery } from '../vehicleGroups/vehicleGroupsSlice';
import moment from 'moment';

export const useVehicles = () => {
  const user = useAppSelector(getUser);
  const vehiclesQuery = useGetVehiclesQuery();
  const groupsQuery = useGetVehicleGroupsQuery({ user });
  // TODO: In JUP-3052: check if this can be moved into a transformResponse function
  const vehicles: VehicleWithGroups[] = (vehiclesQuery?.data?.items || []).map(
    (vehicle: Vehicle) => {
      return mergeVehicleWithGroups(vehicle, groupsQuery.data || []);
    },
  );
  return {
    vehicles,
    groups: groupsQuery.data || [],
    isLoading: vehiclesQuery.isLoading || groupsQuery.isLoading,
    error: vehiclesQuery.error || groupsQuery.error,
  };
};

const transformPaymentMethods = (vehicle: Vehicle) => {
  vehicle.paymentMethods = vehicle.paymentMethods.map((paymentMethod) => {
    const convertedExpiry = moment(paymentMethod.expiry, 'MM-YY').format(
      'MM/YYYY',
    );
    return {
      ...paymentMethod,
      expiry: convertedExpiry,
    };
  });
  return vehicle;
};

enhancedApi.enhanceEndpoints({
  endpoints: {
    getVehicles: {
      transformResponse: (response) => {
        response.items.map((vehicle: Vehicle) => {
          return transformPaymentMethods(vehicle);
        });
        return response;
      },
    },
    updateServiceActivations: {
      transformResponse: (response) => {
        return transformPaymentMethods(response);
      },
    },
  },
});

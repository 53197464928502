import { DashboardPanel } from '../DashboardPanel';
import React, { useEffect, useMemo, useState } from 'react';
import { OperationalCostPanelHeader } from './OperationalCostPanelHeader';
import { OperationalCostPanelBody } from './OperationalCostPanelBody';
import { OperationalCostPanelFooter } from './OperationalCostPanelFooter';
import {
  getDashboardCurrencyFilter,
  resetTransactionsFilters,
  useDashboardFilteredTransactions,
} from '../../../../state/transactions/transactionFilterSlice';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import { TRANSACTIONS_ROUTE } from '../../../../routes/AppRoutes';
import { useNavigate } from 'react-router-dom';
import { useTransactions } from '../../../../state/transactions/useTransactions';
import { ServiceType } from '../../../../codegen/vehicles';
import { ServiceTypeColors } from '../../../../components/colors/ServiceColors';

type ChartData = {
  service: ServiceType;
  color: string;
  amount: number;
};

export const OperationalCostPanel: React.FC = () => {
  const { transactions, isLoading } = useTransactions();
  const { filteredTransactions: filteredTransactions } =
    useDashboardFilteredTransactions();
  const currency = useAppSelector(getDashboardCurrencyFilter);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [serviceFilter, setServiceFilter] = useState(
    new Map<ServiceType, boolean>([]),
  );

  useEffect(() => {
    if (!isLoading) {
      const services = (transactions || [])
        .map((t) => t.serviceType)
        .map((s) => [s, true] as [ServiceType, boolean]);

      setServiceFilter(new Map(services));
    }
  }, [transactions, isLoading]);

  const transactionsForChart = useMemo(() => {
    const enabledServices = Array.from(serviceFilter)
      .filter(([_, selected]) => selected)
      .map(([service]) => service);

    return filteredTransactions.filter((t) =>
      enabledServices.includes(t.serviceType),
    );
  }, [filteredTransactions, serviceFilter]);

  const totalCost = useMemo(() => {
    return transactionsForChart
      .map((t) => parseFloat(t.totalAmountGross || '0'))
      .reduce((a, b) => a + b, 0);
  }, [transactionsForChart]);

  const filteredChartData = useMemo(() => {
    const costByServiceMap: { [key in ServiceType]: number } = {
      FUELING: 0,
      BREAKDOWN: 0,
      PARKING: 0,
      WASHING: 0,
    };

    transactionsForChart.forEach((t) => {
      costByServiceMap[t.serviceType] += parseFloat(t.totalAmountGross || '0');
    });

    const chartData: ChartData[] = [
      {
        service: ServiceType.Fueling,
        color: ServiceTypeColors[ServiceType.Fueling].colorCssClass,
        amount: costByServiceMap[ServiceType.Fueling],
      },
      {
        service: ServiceType.Breakdown,
        color: ServiceTypeColors[ServiceType.Breakdown].colorCssClass,
        amount: costByServiceMap[ServiceType.Breakdown],
      },
      {
        service: ServiceType.Parking,
        color: ServiceTypeColors[ServiceType.Parking].colorCssClass,
        amount: costByServiceMap[ServiceType.Parking],
      },
      {
        service: ServiceType.Washing,
        color: ServiceTypeColors[ServiceType.Washing].colorCssClass,
        amount: costByServiceMap[ServiceType.Washing],
      },
    ];

    return chartData.filter((entry) => serviceFilter.get(entry.service));
  }, [transactionsForChart, serviceFilter]);

  const handleButtonClick = () => {
    dispatch(resetTransactionsFilters());
    navigate(TRANSACTIONS_ROUTE);
  };

  return (
    <DashboardPanel
      header={<OperationalCostPanelHeader />}
      testId="dashboard-operational-costs-panel"
      body={
        <OperationalCostPanelBody
          isLoading={isLoading}
          currency={currency}
          chartData={filteredChartData}
          totalAmount={totalCost}
        />
      }
      footer={
        <OperationalCostPanelFooter
          isLoading={isLoading}
          totalAmount={totalCost}
          currency={currency}
          onButtonClick={handleButtonClick}
        />
      }
    />
  );
};

import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { TransactionSidebarRow } from './TransactionSidebarRow';
import { FormattedAddress } from '../../../../components/FormattedAddress';
import { Poi } from '../../../../codegen/transactions';

type StationDetailsTableProps = {
  poi?: Poi;
  pumpNumber?: number;
};

export const StationDetailsTable = ({
  poi,
  pumpNumber,
}: StationDetailsTableProps) => {
  if (!poi) {
    return <></>;
  }

  return (
    <>
      <h6 className="padding-left-10 margin-top-20">
        <CustomFormattedMessage id="common.transaction.stationDetails" />
      </h6>
      <table
        className="table table-condensed"
        data-testid={'transactions-sidebar-station-details'}
      >
        <colgroup>
          <col style={{ width: 150 }} />
          <col />
        </colgroup>
        <tbody>
          <TransactionSidebarRow label="common.contact.name">
            {poi.name}
          </TransactionSidebarRow>
          {poi.brand && (
            <TransactionSidebarRow label="common.transaction.stationDetails.brand">
              {poi.brand}
            </TransactionSidebarRow>
          )}
          <TransactionSidebarRow label="common.contact.address">
            <FormattedAddress {...poi.address} />
          </TransactionSidebarRow>
          {pumpNumber && (
            <TransactionSidebarRow label="common.transaction.stationDetails.pumpNo">
              {pumpNumber}
            </TransactionSidebarRow>
          )}
        </tbody>
      </table>
    </>
  );
};

import React from 'react';
import { ServiceRepresentation } from '../../../../components/dataRepresentation/ServiceRepresentation';
import { DIALOGUE_DATA_COLOR } from './ServiceConfigurationDialogue';
import Switch from '@rio-cloud/rio-uikit/lib/es/Switch';
import {
  CustomFormattedMessage,
  TranslationsKeys,
  useCustomIntl,
} from '../../../../i18n/i18n';
import { useVehicleDialogueFormContext } from './ServiceConfigurationForm';
import map from 'lodash/map';
import { ServiceType } from '../../../../codegen/vehicles';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { ServiceCardRepresentation } from '../../../../components/dataRepresentation/ServiceCardRepresentation';
import { MaskedCardNumber } from '../../../../components/MaskedCardNumber';
import { FormField } from '../../../../components/form/FormField';
import { Controller } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { InfoPopup } from '../../../../components/InfoPopup';

type ServiceExpanderProps = {
  serviceType: ServiceType;
};

export const ServiceExpander: React.FC<ServiceExpanderProps> = ({
  serviceType,
}) => {
  const { control, isServiceEnabled, getPaymentMethods } =
    useVehicleDialogueFormContext();
  const enabled = isServiceEnabled(serviceType);
  const intl = useCustomIntl();

  const serviceCardOptions = [
    {
      id: '',
      label: (
        <div className={'display-flex justify-content-end'}>
          <CustomFormattedMessage
            id={'onboarding.dialogue.serviceCards.none'}
          />
        </div>
      ),
    },
  ];

  map(getPaymentMethods(), (paymentMethod) => {
    serviceCardOptions.push({
      id: paymentMethod.id,
      label: (
        <div className={'display-flex justify-content-between'}>
          <ServiceCardRepresentation
            paymentPartner={paymentMethod.paymentPartner}
          />
          <div className={'margin-left-20'}>
            <MaskedCardNumber lastFourDigits={paymentMethod.lastFourDigits} />
          </div>
        </div>
      ),
    });
  });

  const explanationSelectedService =
    (`onboarding.dialogue.services.explanation.${serviceType.toLocaleLowerCase()}` +
      `${import.meta.env.MODE === 'prod' ? '.prod' : ''}`) as TranslationsKeys;

  return (
    <div
      className={'margin-top-20'}
      data-testid={`onboarding-dialogue-body-service-${serviceType}`}
    >
      <div className={'panel panel-default margin-bottom-0'}>
        <div className="panel-body">
          <div className={'display-flex align-items-center'}>
            <div className={'margin-right-10'}>
              <Controller
                name={`serviceActivations.${serviceType}.enabled`}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    data-testid={'onboarding-dialogue-body-serviceSwitch'}
                    className={'text-right'}
                    minWidth={48}
                    checked={value}
                    onChange={onChange}
                    enabledText={intl.formatMessage({ id: 'common.switch.on' })}
                    disabledText={intl.formatMessage({
                      id: 'common.switch.off',
                    })}
                  />
                )}
                control={control}
                rules={{
                  required: true,
                }}
              />
            </div>
            <div className={`${DIALOGUE_DATA_COLOR} text-size-20`}>
              <ServiceRepresentation
                serviceType={serviceType}
                representationSize="large"
              />
            </div>
            <div
              data-testid={`service-type-information-button-${serviceType}`}
              className={'margin-left-5'}
            >
              <InfoPopup
                dataTestid={'service-type-information'}
                zIndex={10000}
                width={400}
                placement={'right'}
                marginTop={0}
              >
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <CustomFormattedMessage id={explanationSelectedService} />
                </div>
              </InfoPopup>
            </div>
          </div>
        </div>
        {enabled && (
          <div
            className={'margin-10'}
            data-testid={'onboarding-dialogue-body-fuelCardTypeSection'}
          >
            <Controller
              name={`serviceActivations.${serviceType}.paymentMethodAssignments`}
              render={({ field: { onChange, value } }) => (
                <FormField>
                  <Select
                    aria-labelledby="card-type-label"
                    placeholder={
                      <CustomFormattedMessage
                        id={
                          'onboarding.dialogue.paymentMethodDetails.selectCardTypePlaceholder'
                        }
                      />
                    }
                    options={serviceCardOptions}
                    onChange={(e) => {
                      onChange(
                        isEmpty(e.id) ? [] : [{ paymentMethodId: e.id }],
                      );
                    }}
                    value={isEmpty(value) ? [] : [value[0].paymentMethodId]}
                  />
                </FormField>
              )}
              control={control}
              rules={{
                required: true,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

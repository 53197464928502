import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import React, { useEffect } from 'react';
import { InfoPopup } from '../../../components/InfoPopup';
import {
  DEFAULT_CURRENCY,
  getDashboardCurrencyFilter,
  getFromFilter,
  getPeriodFilter,
  getToFilter,
  setDashboardCurrency,
  setFrom,
  setPeriod,
  setServiceTypes,
  setTo,
  useDashboardFilteredTransactions,
} from '../../../state/transactions/transactionFilterSlice';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import moment from 'moment';
import {
  useCurrencyOptions,
  useTransactions,
} from '../../../state/transactions/useTransactions';
import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import { useTransactionUpdateState } from '../../../state/transactions/transactionUpdateSlice';
import {
  parsePeriod,
  usePeriodSelectOptions,
} from '../../../usePeriodSelectOptions';

export const FilterBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useTransactions();
  const currency = useAppSelector(getDashboardCurrencyFilter);
  const { serviceTypeOptions } = useDashboardFilteredTransactions();
  const from = useAppSelector(getFromFilter);
  const to = useAppSelector(getToFilter);
  const period = useAppSelector(getPeriodFilter);
  const currencyOptions = useCurrencyOptions();
  const periodSelectOptions = usePeriodSelectOptions();

  const { hasTransactionUpdate, reset: resetTransactionUpdateState } =
    useTransactionUpdateState();

  const { resetApiState } = useDashboardFilteredTransactions();

  const handleRefreshTransactions = () => {
    resetApiState();
    resetTransactionUpdateState();
  };

  const isAvailableCurrency = (c: string) => {
    return currencyOptions.some((option) => option.id === c);
  };

  useEffect(() => {
    if (!isLoading && !isAvailableCurrency(currency)) {
      const preSelectedCurrency = isAvailableCurrency(DEFAULT_CURRENCY)
        ? DEFAULT_CURRENCY
        : currencyOptions[0].id;

      dispatch(setDashboardCurrency(preSelectedCurrency));
    }
  }, [isLoading, currency, currencyOptions]);

  const handleDatePicked = (callback: (millis: number) => any) => {
    return (value: moment.Moment, valid: boolean) => {
      if (valid) {
        const millis = value.valueOf();
        dispatch(callback(millis));
      }
    };
  };

  return (
    <TableToolbar>
      <div className="table-toolbar-container ">
        <div className="table-toolbar-group-left justify-content-center">
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <div data-testid={'dashboard-filter-bar-start-date-picker'}>
                <label>
                  <CustomFormattedMessage id={'common.startDate'} />
                </label>
                <DatePicker
                  closeOnSelect
                  timeFormat={false}
                  value={from}
                  minWidth={130}
                  onChange={handleDatePicked(setFrom)}
                  dateFormat={'DD/MM/YYYY'}
                />
              </div>
            </div>
          </div>
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <div data-testid={'dashboard-filter-bar-end-date-picker'}>
                <label>
                  <CustomFormattedMessage id={'common.endDate'} />
                </label>
                <DatePicker
                  aria-label={'end-date'}
                  closeOnSelect
                  timeFormat={false}
                  value={to}
                  minWidth={130}
                  onChange={handleDatePicked(setTo)}
                  dateFormat={'DD/MM/YYYY'}
                />
              </div>
            </div>
          </div>
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <div data-testid={'dashboard-filter-bar-period-selector'}>
                <label>
                  <CustomFormattedMessage id={'common.period'} />
                </label>
                <Select
                  useClear
                  className={'width-200'}
                  id={'periodSelector'}
                  placeholder={
                    <CustomFormattedMessage id={'common.selectPeriod'} />
                  }
                  options={periodSelectOptions}
                  onChange={(option) => {
                    const value = option ? parsePeriod(option.id) : undefined;
                    dispatch(setPeriod(value));
                  }}
                  value={period ? [period.toString()] : []}
                />
              </div>
            </div>
          </div>
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar gap-5">
              <div data-testid={'dashboard-filter-bar-currency-selector'}>
                <label>
                  <CustomFormattedMessage id={'common.currency'} />
                </label>
                <Select
                  className={'width-200'}
                  id={'currencySelector'}
                  options={currencyOptions}
                  onChange={(option) =>
                    dispatch(setDashboardCurrency(option.id))
                  }
                  value={[currency]}
                />
              </div>
              <div>
                <InfoPopup>
                  <CustomFormattedMessage id={'common.currencyDisclaimer'} />
                </InfoPopup>
              </div>
            </div>
          </div>
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar gap-5">
              <div data-testid={'dashboard-filter-bar-service-types-selector'}>
                <label className="min-width-300">
                  <CustomFormattedMessage
                    id={'dashboard.filter.serviceTypes.header'}
                  />
                </label>
                <Multiselect
                  placeholder={
                    <CustomFormattedMessage
                      id={'dashboard.filter.serviceTypes.placeholder'}
                    />
                  }
                  options={serviceTypeOptions}
                  onChange={(options: []) => dispatch(setServiceTypes(options))}
                />
              </div>
            </div>
          </div>
          {hasTransactionUpdate && (
            <div className="table-toolbar-column">
              <div className="btn-toolbar table-btn-toolbar">
                <Button
                  data-testid="dashboard-filter-bar-refresh-button"
                  bsStyle={'primary'}
                  onClick={handleRefreshTransactions}
                >
                  <span className={'rioglyph rioglyph-refresh'} />
                  <CustomFormattedMessage
                    id={'transactions.button.refreshTransactions'}
                  />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </TableToolbar>
  );
};

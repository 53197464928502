import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import React, { useEffect } from 'react';
import { ServiceExpander } from './ServiceExpander';
import { CustomFormattedMessage, useCustomIntl } from '../../../../i18n/i18n';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import {
  closeConfigurationDialogue,
  DialogMode,
  getDialogMode,
  getSelectedVehicle,
  isConfigurationDialogueOpen,
  openAddNewServiceCardDialogue,
  setDialogMode,
} from '../../state/onboardingUiSlice';
import { VehicleHeadline } from '../VehicleHeadline';
import { VehicleDialogFooter } from './VehicleDialogFooter';
import { FormProvider, useForm } from 'react-hook-form';
import {
  createVehicleDialogueFormData,
  ServiceConfigurationForm,
} from './ServiceConfigurationForm';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { ServiceCardPanel } from './ServiceCardPanel';
import {
  enhancedApi,
  PaymentPartnerType,
  ServiceActivation,
  useUpdateServiceActivationsMutation,
} from '../../../../codegen/vehicles';
import { RemoveConfirmationDialogue } from './RemoveConfirmationDialogue';
import { AddNewServiceCardDialogue } from './AddNewServiceCardDialogue';
import find from 'lodash/find';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { appConfiguration } from '../../../../configuration/appConfiguration';

export const DIALOGUE_DATA_COLOR = 'text-color-dark';

export const ServiceConfigurationDialogue: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();
  const dialogMode = useAppSelector(getDialogMode);
  const showConfigurationDialog = useAppSelector(isConfigurationDialogueOpen);
  const vehicle = useAppSelector(getSelectedVehicle);
  const formUtils = useForm<ServiceConfigurationForm>();
  const [
    updateServiceActivations,
    { isLoading: isUpdateServiceActivationLoading },
  ] = useUpdateServiceActivationsMutation();

  useEffect(() => {
    formUtils.reset(createVehicleDialogueFormData(vehicle));
  }, [vehicle]);

  const formData = formUtils.watch();
  const { dirtyFields } = formUtils.formState;
  const isServiceActivationsDirty = () => {
    return (
      !!find(dirtyFields.serviceActivations, (serviceActivation) => {
        return (
          serviceActivation.enabled ||
          !!serviceActivation.paymentMethodAssignments
        );
      }) || false
    );
  };

  if (!vehicle || !showConfigurationDialog) {
    return <></>;
  }

  const handleClose = () => {
    dispatch(closeConfigurationDialogue());
  };

  const handleConfirmation = () => {
    map(formData.serviceActivations, (serviceActivation: ServiceActivation) => {
      if (
        isEmpty(serviceActivation.paymentMethodAssignments) &&
        serviceActivation.enabled
      ) {
        serviceActivation.enabled = false;
      }
      if (!serviceActivation.enabled) {
        serviceActivation.paymentMethodAssignments = [];
      }
    });
    updateServiceActivations({
      vehicleId: vehicle.id,
      updateServiceActivationsRequest: {
        serviceActivations: Object.values(formData.serviceActivations),
      },
    }).then((result: any) => {
      if (result.data) {
        formUtils.reset(formData);
        dispatch(
          enhancedApi.util.updateQueryData(
            'getVehicles',
            undefined,
            (draftVehicles) => {
              draftVehicles.items = draftVehicles.items.map((v) => {
                if (v.id === result.data.id) {
                  v = result.data;
                }
                return v;
              });
            },
          ),
        );
      }
    });
  };

  const isActive = (value: DialogMode) => {
    if (dialogMode === value) {
      return 'active';
    }
  };

  const renderServiceCards = () => {
    const fuelCards = map(formData?.paymentMethods, (paymentMethod) => (
      <ServiceCardPanel
        data-testid={'onboarding-dialogue-body-service-card-panel'}
        vehicleId={vehicle.id}
        paymentMethod={paymentMethod}
      />
    ));

    const defaultPaymentPartnerType =
      appConfiguration.supportedPaymentPartnerTypes[0];

    return (
      <>
        <div className="justify-content-center margin-bottom-20">
          {fuelCards}
        </div>
        <div className="display-flex justify-content-center margin-bottom-20">
          <Button
            data-testid={'onboarding-dialogue-add-new-service-button'}
            bsStyle={'primary'}
            type={'button'}
            onClick={() =>
              dispatch(
                openAddNewServiceCardDialogue({
                  paymentPartnerType:
                    defaultPaymentPartnerType as PaymentPartnerType,
                }),
              )
            }
            className={'pull-right margin-top-10'}
          >
            <CustomFormattedMessage id="onboarding.addServiceCard.dialogue.title" />
          </Button>
        </div>
      </>
    );
  };

  const renderServices = () => {
    return map(formData?.serviceActivations, (s) => {
      return (
        <ServiceExpander
          serviceType={s.serviceType}
          key={s.serviceType}
        />
      );
    });
  };

  const renderServiceCardTab = () => {
    return !isServiceActivationsDirty() ? (
      <li
        key={2}
        data-testid={'onboarding-dialogue-tab-serviceCards'}
        className={isActive(DialogMode.SERVICE_CARDS)}
      >
        <div onClick={() => dispatch(setDialogMode(DialogMode.SERVICE_CARDS))}>
          <CustomFormattedMessage id={'onboarding.dialogue.serviceCards.tab'} />
        </div>
      </li>
    ) : (
      <div
        data-testid={'onboarding-dialogue-tab-serviceCards-disabled'}
        className="display-flex flex-column justify-content-center padding-left-10"
      >
        <OverlayTrigger
          key={crypto.randomUUID()}
          placement={'top-start'}
          overlay={
            <Tooltip
              id="tooltip"
              allowOnTouch
            >
              <CustomFormattedMessage
                id={'onboarding.dialogue.serviceCards.tab.toolTip'}
              />
            </Tooltip>
          }
        >
          <div className="display-flex align-items-center">
            <div className="text-color-gray">
              <CustomFormattedMessage
                id={'onboarding.dialogue.serviceCards.tab'}
              />
            </div>
            <span className="rioglyph rioglyph-question-sign text-color-danger text-size-16 padding-left-5" />
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  const renderBody = () => {
    let content: React.JSX.Element;
    if (DialogMode.SERVICES === dialogMode) {
      content = (
        <>
          <div className="text-size-16 text-medium margin-top-20 margin-bottom-20">
            <CustomFormattedMessage
              id={'onboarding.dialogue.services.message'}
            />
          </div>
          {renderServices()}
        </>
      );
    } else {
      content = (
        <>
          <div className="text-size-16 text-medium margin-top-20 margin-bottom-20">
            <CustomFormattedMessage
              id={'onboarding.dialogue.serviceCards.message'}
            />
          </div>
          {renderServiceCards()}
        </>
      );
    }

    return (
      <div>
        <div className="margin-top-5 margin-bottom-50">
          <VehicleHeadline vehicle={vehicle} />
        </div>
        <ul className="nav nav-tabs user-select-none">
          <li
            key={1}
            data-testid={'onboarding-dialogue-tab-services'}
            className={isActive(DialogMode.SERVICES)}
          >
            <div onClick={() => dispatch(setDialogMode(DialogMode.SERVICES))}>
              <CustomFormattedMessage id={'onboarding.dialogue.services.tab'} />
            </div>
          </li>
          {renderServiceCardTab()}
        </ul>
        {content}
      </div>
    );
  };

  return (
    <div>
      <FormProvider {...formUtils}>
        <form>
          <Dialog
            aria-label={'vehicle-configuration'}
            useOverflow
            bsSize={Dialog.SIZE_SM}
            show
            onHide={handleClose}
            title={intl.formatMessage({ id: 'onboarding.dialogue.title' })}
            body={renderBody()}
            footer={
              <VehicleDialogFooter
                dialogMode={dialogMode}
                saveEnabled={isServiceActivationsDirty()}
                isLoading={isUpdateServiceActivationLoading}
                onClose={handleClose}
                onSave={() => handleConfirmation()}
              />
            }
          />
          <AddNewServiceCardDialogue />
          <RemoveConfirmationDialogue />
        </form>
      </FormProvider>
    </div>
  );
};

import { BackendErrors } from '../models';
import { TranslationsKeys } from './i18n';
import {
  VehicleType,
  PaymentMethodType,
  PaymentPartnerType,
  ServiceType,
  ServicePartner,
  TransactionItemUnit,
} from '../codegen/vehicles';
import { TransactionState } from '../codegen/transactions';
import { BookingStatus } from '../codegen/bookings';

type EnumTranslation<T extends string> = {
  [key in T]: TranslationsKeys;
};

export const SERVICE_TO_TRANSLATION_KEY: EnumTranslation<ServiceType> = {
  FUELING: 'common.serviceType.fueling',
  BREAKDOWN: 'common.serviceType.breakdown',
  PARKING: 'common.serviceType.parking',
  WASHING: 'common.serviceType.washing',
};

export const SERVICE_PARTNER_TO_TRANSLATION_KEY: EnumTranslation<ServicePartner> =
  {
    MOCK_FUELING_PARTNER: 'common.servicePartnerType.mockFueling',
    MOCK_BREAKDOWN_PARTNER: 'common.servicePartnerType.mockBreakdown',
    MOBILE_24: 'common.servicePartnerType.mobile24',
    TRAVIS: 'common.servicePartnerType.travis',
    MOCK_ROADSIDE_PARTNER: 'common.servicePartnerType.mockRoadside',
  };

export const PAYMENT_METHOD_TO_TRANSLATION_KEY: EnumTranslation<PaymentMethodType> =
  {
    FUEL_CARD: 'common.paymentMethodType.fuelCard',
  };

export const VEHICLE_TYPE_TO_TRANSLATION_KEY: EnumTranslation<VehicleType> = {
  TRUCK: 'common.vehicleType.truck',
  BUS: 'common.vehicleType.bus',
  VAN: 'common.vehicleType.van',
  TRAILER: 'common.vehicleType.trailer',
  CAR: 'common.vehicleType.car',
};

export const VEHICLE_TYPE_PLURAL_TO_TRANSLATION_KEY: EnumTranslation<VehicleType> =
  {
    TRUCK: 'common.vehicleType.trucks',
    BUS: 'common.vehicleType.buses',
    VAN: 'common.vehicleType.vans',
    TRAILER: 'common.vehicleType.trailers',
    CAR: 'common.vehicleType.cars',
  };

export const TRANSACTION_STATE_TO_TRANSLATION_KEY: EnumTranslation<TransactionState> =
  {
    CREATED: 'common.transactionState.created',
    IN_PROGRESS: 'common.transactionState.inProgress',
    COMPLETED: 'common.transactionState.completed',
    DELIVERED: 'common.transactionState.delivered',
    FAILED: 'common.transactionState.failed',
    CANCELED: 'common.transactionState.canceled',
  };

export const BOOKING_STATUS_TO_TRANSLATION_KEY: EnumTranslation<BookingStatus> =
  {
    PLANNED: 'common.booking.planned',
    IN_PROGRESS: 'common.booking.inProgress',
    COMPLETED: 'common.booking.completed',
    CANCELED: 'common.booking.canceled',
  };

export const UNIT_TYPE_TO_TRANSLATION_KEY: EnumTranslation<TransactionItemUnit> =
  {
    LITER: 'common.transaction.unit.liters',
    KG: 'common.transaction.unit.kg',
  };

export const FUEL_CARD_TYPE_TO_TRANSLATION_KEY: EnumTranslation<PaymentPartnerType> =
  {
    MOCK_FUELING_PAYMENT: 'common.fuelCardType.manFuelCard',
    UTA_PAYMENT: 'common.fuelCardType.utaFuelCard',
  };

export const ERROR_CODE_TO_TRANSLATION_KEY: EnumTranslation<BackendErrors> = {
  INVALID_FUEL_CARD: 'onboarding.dialogue.fuelCardForm.invalidCardError',
  EXTERNAL_LOGIN_ERROR:
    'onboarding.addServiceCard.dialogue.externalLogin.errorMessage',
};

import { CustomFormattedMessage } from '../../i18n/i18n';
import { PAYMENT_METHOD_TO_TRANSLATION_KEY } from '../../i18n/enumMappings';
import { PaymentPartnerIcon } from '../icons/PaymentPartnerIcon';
import { PaymentMethodType, PaymentPartnerType } from '../../codegen/vehicles';

const PAYMENT_PARTNER_TO_CLASS: { [key in PaymentPartnerType]: string } = {
  MOCK_FUELING_PAYMENT: 'margin-right-5 height-20',
  UTA_PAYMENT: 'margin-right-5 height-20',
};

export const PaymentPartnerRepresentation = ({
  paymentPartner,
  paymentMethod,
}: {
  paymentPartner: PaymentPartnerType;
  paymentMethod: PaymentMethodType;
}) => {
  return (
    <>
      <PaymentPartnerIcon
        paymentPartner={paymentPartner}
        className={PAYMENT_PARTNER_TO_CLASS[paymentPartner]}
      />
      <CustomFormattedMessage
        id={PAYMENT_METHOD_TO_TRANSLATION_KEY[paymentMethod]}
      />
    </>
  );
};

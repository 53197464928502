import React, { useCallback, useState } from 'react';
import { RowData, Table } from '../../../../components/table/Table';
import { TableColumn } from '../../../../components/table/TableRow';
import { BookingRow, mapBookingsToRows } from './mapBookingsToRows';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import {
  CustomFormattedDate,
  CustomFormattedMessage,
  TranslationsKeys,
  useCustomIntl,
} from '../../../../i18n/i18n';
import { useVehicles } from '../../../../state/vehicles/useVehicles';
import { VEHICLE_TYPE_TO_TRANSLATION_KEY } from '../../../../i18n/enumMappings';
import { ServiceRepresentation } from '../../../../components/dataRepresentation/ServiceRepresentation';
import { MonetaryValue } from '../../../../components/MonetaryValue';
import { BookingStatusLabel } from '../BookingStatusLabel';
import {
  BOOKING_TABLE_PAGE_SIZE,
  getBookingsPage,
  setPage,
  useTableFilteredBookings,
} from '../../../../state/bookings/bookingsFilterSlice';
import { Pagination } from '../../../../components/table/Pagination';
import { VehicleRepresentation } from '../../../../components/dataRepresentation/VehicleRepresentation';
import { VehicleGroupsRepresentation } from '../../../../components/dataRepresentation/VehicleGroupsRepresentation';
import { BookingsTableToolbar } from './BookingsTableToolbar';

export const BookingsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();

  const { filteredBookings, totalBookings, isFetching } =
    useTableFilteredBookings();
  const { vehicles } = useVehicles();

  const page = useAppSelector(getBookingsPage);

  const [highlightedRow, setHighlightedRow] = useState<string | undefined>();

  const handlePageChanged = (pageNumber: number) => {
    dispatch(setPage(pageNumber));
  };

  const getLabel = useCallback(
    (id: TranslationsKeys) => intl.formatMessage({ id }),
    [],
  );

  const columns: TableColumn<BookingRow>[] = [
    {
      id: 'vehicleName',
      label: getLabel('bookings.bookingsTable.header.vehicle'),
      renderer: (booking: BookingRow) => (
        <VehicleRepresentation
          vehicleType={booking.vehicleType}
          vehicleName={booking.vehicleName}
        />
      ),
    },
    {
      id: 'vehicleType',
      label: getLabel('bookings.bookingsTable.header.type'),
      renderer: (booking: BookingRow) => {
        if (!booking.vehicleType) {
          return <></>;
        }
        return (
          <CustomFormattedMessage
            id={VEHICLE_TYPE_TO_TRANSLATION_KEY[booking.vehicleType]}
          />
        );
      },
    },
    {
      id: 'vehicleGroups',
      label: getLabel('bookings.bookingsTable.header.groups'),
      renderer: (booking: BookingRow) => (
        <VehicleGroupsRepresentation vehicleGroups={booking.vehicleGroups} />
      ),
    },
    {
      id: 'service',
      label: getLabel('bookings.bookingsTable.header.service'),
      renderer: (booking: BookingRow) => (
        <ServiceRepresentation serviceType={booking.service} />
      ),
    },
    {
      id: 'arrival',
      label: getLabel('bookings.bookingsTable.header.arrival'),
      renderer: (booking: BookingRow) => (
        <CustomFormattedDate value={booking.arrival} />
      ),
    },
    {
      id: 'estimatedTotalPrice',
      label: getLabel('bookings.bookingsTable.header.estimatedTotalPrice'),
      renderer: (booking: BookingRow) => (
        <span className="text-medium">
          {!!booking.estimatedTotalPrice && !!booking.currency && (
            <MonetaryValue
              amount={booking.estimatedTotalPrice}
              currency={booking.currency}
            />
          )}
        </span>
      ),
    },
    {
      id: 'status',
      label: getLabel('bookings.bookingsTable.header.status'),
      renderer: (booking: BookingRow) => (
        <BookingStatusLabel status={booking.status} />
      ),
    },
    {
      id: 'driverName',
      label: getLabel('bookings.bookingsTable.header.driverName'),
      renderer: (booking: BookingRow) => <>{booking.driverName}</>,
    },
  ];

  const rowData: RowData<BookingRow>[] = mapBookingsToRows(
    filteredBookings,
    vehicles,
  );

  const numberOfPages = Math.ceil(totalBookings / BOOKING_TABLE_PAGE_SIZE);

  return (
    <div className="display-flex flex-column height-100pct">
      <BookingsTableToolbar />
      <div
        className="table-responsive overflow-y-auto"
        data-testid="bookings-body-bookings-table"
      >
        <Table
          columns={columns}
          rowData={rowData}
          highlightedRow={highlightedRow}
          onRowClicked={(key) => {
            setHighlightedRow(key);
          }}
          isLoading={isFetching}
        />
      </div>
      <Pagination
        currentPage={page}
        numberOfPages={numberOfPages}
        onPageChanged={handlePageChanged}
      />
    </div>
  );
};

import React from 'react';
import fuelingLogo from '../../assets/images/partners/fueling-placeholder.png';
import breakdownLogo from '../../assets/images/partners/breakdown-placeholder.png';
import roadSideLogo from '../../assets/images/partners/roadside-placeholder.png';
import { ServicePartner } from '../../codegen/vehicles';

type ServicePartnerIconProps = {
  servicePartner: ServicePartner;
  className?: string;
};

const servicePartnersToIcon: { [key in ServicePartner]: string } = {
  MOCK_FUELING_PARTNER: fuelingLogo,
  MOCK_BREAKDOWN_PARTNER: breakdownLogo,
  MOCK_ROADSIDE_PARTNER: roadSideLogo,
  MOBILE_24: breakdownLogo, // FIXME JUP-3364 add correct logo once it's given
  TRAVIS: roadSideLogo, // FIXME JUP-3364 add correct logo once it's given
};

export const ServicePartnerIcon: React.FC<ServicePartnerIconProps> = ({
  servicePartner,
  className,
}) => (
  <img
    alt={'logo'}
    src={servicePartnersToIcon[servicePartner]}
    className={className}
  />
);

import { ServiceType } from '../../codegen/transactions';

type ColorInfo = {
  colorCssClass: string;
  textColorCssClass: string;
};

export const ServiceTypeColors: Record<ServiceType, ColorInfo> = {
  [ServiceType.Fueling]: {
    colorCssClass: 'color-warmup-cherokee',
    textColorCssClass: 'text-color-warmup-cherokee',
  },
  [ServiceType.Breakdown]: {
    colorCssClass: 'color-warmup-raspberry',
    textColorCssClass: 'text-color-warmup-raspberry',
  },
  [ServiceType.Parking]: {
    colorCssClass: 'color-warmup-victoria',
    textColorCssClass: 'text-color-warmup-victoria',
  },
  [ServiceType.Washing]: {
    colorCssClass: 'color-coldplay-fountain',
    textColorCssClass: 'text-color-coldplay-fountain',
  },
};

import BarChart from '@rio-cloud/rio-uikit/BarChart';
import Bar from '@rio-cloud/rio-uikit/Bar';
import Legend from '@rio-cloud/rio-uikit/Legend';
import ChartTooltip from '@rio-cloud/rio-uikit/lib/es/ChartTooltip';
import { ServiceType } from '../../../../codegen/transactions';
import { ServiceTypeColors } from '../../../../components/colors/ServiceColors';
import { useCustomIntl } from '../../../../i18n/i18n';
import { SERVICE_TO_TRANSLATION_KEY } from '../../../../i18n/enumMappings';
import { useAppSelector } from '../../../../state/hooks';
import { getDashboardCurrencyFilter } from '../../../../state/transactions/transactionFilterSlice';
import { CostsPerService } from './chartData';
import { getCurrencyDisplay } from '../../../../components/MonetaryValue';

export type ChartData = {
  label: string;
  data: CostsPerService;
}[];

type Props = {
  chartData: ChartData;
};

export const CostsPerServiceOverTimeChart = ({ chartData }: Props) => {
  const intl = useCustomIntl();

  const currency = useAppSelector(getDashboardCurrencyFilter);

  const serviceTypes: ServiceType[] = Object.values(ServiceType);

  return (
    <div
      data-testid="costs-per-service-over-time-panel-chart"
      className="panel-default panel-body margin-top-0 margin-bottom-0 height-200 max-width-500"
    >
      <BarChart
        data={chartData}
        layout="horizontal"
        color=""
        dataKey="label"
        legend={
          <Legend
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
          />
        }
        tooltip={
          <ChartTooltip
            formatter={(val: number) =>
              intl.formatNumber(val, {
                style: 'currency',
                currency,
                currencyDisplay: getCurrencyDisplay(currency),
              })
            }
          />
        }
        showGrid
        gridOptions={{ vertical: false }}
        xAxisOptions={{ dataKey: 'label' }}
        yAxisOptions={{ width: 40 }}
        bars={serviceTypes.map((serviceType: ServiceType) => (
          <Bar
            key={serviceType}
            dataKey={`data.${serviceType}`}
            name={intl.formatMessage({
              id: SERVICE_TO_TRANSLATION_KEY[serviceType],
            })}
            color={ServiceTypeColors[serviceType].colorCssClass}
            stackId="byMonth"
            barSize={26}
          />
        ))}
      />
    </div>
  );
};

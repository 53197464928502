import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { appConfiguration } from '../../configuration/appConfiguration';
import { TransactionUpdatedEvent } from '../../state/transactions/dto';
import {
  PaymentMethodType,
  ServiceType,
  TransactionState,
} from '../../codegen/transactions';
import { PaymentPartnerType, ServicePartner } from '../../codegen/vehicles';

type Props = {
  onClick: (event: TransactionUpdatedEvent) => void;
};

// TODO JUP-1267: Remove this component once the GraphQL mock supports subscriptions
/* Hidden button for integration testing */
/* Hint: for manual testing, remove the 'display-none' class bellow. */
export const MockTransactionUpdate = ({ onClick }: Props) => {
  const triggerMockTransactionUpdate = () => {
    const event: TransactionUpdatedEvent = {
      data: {
        onTransactionUpdated: {
          id: '',
          total_amount_gross: '',
          total_amount_net: '',
          currency: '',
          items: [],
          service: ServiceType.Fueling,
          service_partner: ServicePartner.MockFuelingPartner,
          state: TransactionState.Completed,
          created_at: '',
          payment_details: {
            payment_method: PaymentMethodType.FuelCard,
            payment_partner: PaymentPartnerType.MockFuelingPayment,
            paid_at: '',
          },
          mileage: 0,
          vehicle: {
            id: '',
          },
          fueling_details: {
            fuel_station_id: '',
            fuel_station_name: '',
            fuel_station_brand: '',
            fuel_station_address: {
              street: '',
              city: '',
              state: '',
              country: '',
              zip_code: '',
            },
            fuel_pump_number: '',
          },
        },
      },
    };
    onClick(event);
  };

  if (!appConfiguration.mock.transactionUpdate) {
    return <></>;
  }

  return (
    <div className="position-absolute top-10 right-10pct z-index-max display-none">
      <Button
        data-testid="mock-transaction-update-button"
        className="btn btn-danger"
        onClick={triggerMockTransactionUpdate}
      >
        Trigger mock transaction update
      </Button>
    </div>
  );
};

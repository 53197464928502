import {
  CustomFormattedMessage,
  TranslationsKeys,
} from '../../../../i18n/i18n';
import React, { ReactNode } from 'react';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';

type TransactionSidebarRowProps = {
  label: TranslationsKeys;
  labelValues?: Record<
    string,
    PrimitiveType | FormatXMLElementFn<string, string>
  >;
  show?: boolean;
  highlight?: boolean;
  children?: ReactNode;
};
export const TransactionSidebarRow: React.FC<TransactionSidebarRowProps> = ({
  label,
  labelValues,
  show = true,
  highlight = false,
  children,
}) => {
  const textStyle = highlight
    ? 'text-color-black text-medium'
    : 'text-color-gray';

  if (!show) {
    return <></>;
  }

  return (
    <tr>
      <td className={textStyle}>
        <CustomFormattedMessage
          id={label}
          values={labelValues}
        />
      </td>
      <td className={'text-right'}>{children}</td>
    </tr>
  );
};

type TransactionSidebarHeadlineProps = {
  children?: ReactNode;
};

export const TransactionSidebarHeadline: React.FC<
  TransactionSidebarHeadlineProps
> = ({ children }) => {
  return (
    <tr>
      <td
        className={'text-medium margin-top-10 border-top-0'}
        colSpan={2}
      >
        {children}
      </td>
    </tr>
  );
};

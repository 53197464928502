import React, { useCallback, useEffect } from 'react';
import { FormField } from '../../../../components/form/FormField';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import { Controller } from 'react-hook-form';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { SelectOption } from '@rio-cloud/rio-uikit/lib/es/types';
import { useVehicleDialogueFormContext } from './ServiceConfigurationForm';
import moment, { Moment } from 'moment';
import { isMoment } from 'moment/moment';
import { ServiceCardRepresentation } from '../../../../components/dataRepresentation/ServiceCardRepresentation';
import { PaymentPartnerType } from '../../../../codegen/vehicles';
import {
  getSelectedVehicle,
  setPaymentPartnerType,
} from '../../state/onboardingUiSlice';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import {
  edenredUserManager,
  useEdenredToken,
} from '../../../../login/external/edenredUserManager';
import { routeStorage } from '../../../../routes/routeStorage';
import { appConfiguration } from '../../../../configuration/appConfiguration';

export const ServiceCardForm: React.FC = ({}) => {
  const { control, formState, resetField, trigger, getNewServiceCard } =
    useVehicleDialogueFormContext();
  const errors = formState.errors.newServiceCard;
  const dispatch = useAppDispatch();

  useEffect(() => {
    resetField('newServiceCard.cardNumber');
  }, []);

  const paymentPartnerOptions: SelectOption[] =
    appConfiguration.supportedPaymentPartnerTypes.map((paymentPartnerType) => {
      return {
        id: paymentPartnerType,
        label: (
          <ServiceCardRepresentation
            paymentPartner={paymentPartnerType as PaymentPartnerType}
          />
        ),
      };
    });

  const vehicle = useAppSelector(getSelectedVehicle);
  const onClickEdenredLogin = useCallback(() => {
    routeStorage.saveVehicleId(vehicle?.id ?? '');
    edenredUserManager.signinRedirect();
  }, [vehicle]);
  const edenredToken = useEdenredToken();
  const paymentCardProviderLoginRequired =
    getNewServiceCard()?.paymentPartner === PaymentPartnerType.UtaPayment &&
    !edenredToken;

  return (
    <div
      className={'display-grid grid-cols-8 margin-top-15'}
      data-testid={'onboarding-dialogue-body-service-card'}
    >
      <div className={'grid-colspan-8 padding-left-10'}>
        <div className="text-size-16 text-medium margin-bottom-20">
          <CustomFormattedMessage
            id={'onboarding.addServiceCard.dialogue.message'}
          />
        </div>
        <div className="row">
          <div
            className="col-xs-12"
            data-testid={'onboarding-dialogue-body-fuelCardTypeSection'}
          >
            <Controller
              name={'newServiceCard.paymentPartner'}
              render={({ field: { value } }) => (
                <FormField
                  hasError={!!errors?.paymentPartner}
                  errorMessage={
                    <CustomFormattedMessage
                      id={
                        'onboarding.dialogue.fuelCardForm.fuelCardType.errorMessage'
                      }
                    />
                  }
                >
                  <label id={'card-type-label'}>
                    {<CustomFormattedMessage id={'common.card.type'} />}
                  </label>
                  <Select
                    aria-labelledby="card-type-label"
                    placeholder={
                      <CustomFormattedMessage
                        id={
                          'onboarding.dialogue.paymentMethodDetails.selectCardTypePlaceholder'
                        }
                      />
                    }
                    options={paymentPartnerOptions}
                    onChange={(e) => {
                      dispatch(
                        setPaymentPartnerType(e.id as PaymentPartnerType),
                      );
                    }}
                    value={value ? [value.toString()] : []}
                  />
                </FormField>
              )}
              control={control}
              rules={{
                required: true,
              }}
            />
            {paymentCardProviderLoginRequired && (
              <Button
                data-testid="onboarding-dialogue-body-paymentCardProviderLoginButton"
                className={'margin-bottom-10'}
                onClick={onClickEdenredLogin}
                bsStyle="primary"
              >
                <CustomFormattedMessage
                  id={
                    'onboarding.dialogue.fuelCardForm.fuelCardType.edenredLoginButton'
                  }
                />
              </Button>
            )}
          </div>
        </div>
        <div
          className="col-xs-12"
          data-testid={'onboarding-dialogue-body-fuelCardNumberSection'}
        >
          <Controller
            name={'newServiceCard.cardNumber'}
            render={({ field: { onChange, value } }) => (
              <FormField
                hasError={!!errors?.cardNumber}
                errorMessage={
                  <CustomFormattedMessage
                    id={
                      'onboarding.dialogue.fuelCardForm.fuelCardNumber.errorMessage'
                    }
                  />
                }
              >
                <label id={'card-number-label'}>
                  <CustomFormattedMessage id={'common.card.number'} />
                </label>
                <ClearableInput
                  aria-labelledby="card-number-label"
                  onChange={(input: string) => {
                    onChange(input);
                    trigger('newServiceCard.cardNumber');
                  }}
                  onBlur={() => {
                    trigger('newServiceCard.cardNumber');
                  }}
                  value={value}
                  disabled={paymentCardProviderLoginRequired}
                />
              </FormField>
            )}
            control={control}
            rules={{
              required: true,
              pattern: /^(\d{17})$/,
            }}
          />
        </div>
        <div
          className="col-xs-12"
          data-testid={'onboarding-dialogue-body-fuelCardExpirySection'}
        >
          <Controller
            name={'newServiceCard.expiryDate'}
            render={({ field: { onChange } }) => (
              <FormField
                hasError={!!errors?.expiryDate}
                errorMessage={
                  <CustomFormattedMessage
                    id={
                      'onboarding.dialogue.fuelCardForm.expiryDate.errorMessage'
                    }
                  />
                }
              >
                <label id={'card-expiry-label'}>
                  <CustomFormattedMessage id={'common.card.expiryDate'} />
                </label>
                <DatePicker
                  className={'margin-bottom-0 width-150'}
                  dropup
                  closeOnSelect
                  dateFormat={'MM/YYYY'}
                  timeFormat={false}
                  onChange={(event: string | Moment) => {
                    if (isMoment(event)) {
                      event = event.format('MM/YYYY');
                    }
                    onChange(event);
                    trigger('newServiceCard.expiryDate');
                  }}
                  inputProps={{
                    'disabled': paymentCardProviderLoginRequired,
                    'aria-labelledby': 'card-expiry-label',
                  }}
                />
              </FormField>
            )}
            control={control}
            rules={{
              required: true,
              pattern: /^(0[1-9]|1[012])\/\d{4}$/,
              validate: {
                notInThePast: (date) =>
                  moment(date, 'MM/YYYY')
                    .endOf('month')
                    .isAfter(moment().startOf('month')),
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

import { CustomFormattedDate } from '../../../../i18n/i18n';
import { TransactionStateLabel } from '../TransactionStateLabel';
import { ServiceRepresentation } from '../../../../components/dataRepresentation/ServiceRepresentation';
import { ServicePartnerRepresentation } from '../../../../components/dataRepresentation/ServicePartnerRepresentation';
import { PaymentPartnerRepresentation } from '../../../../components/dataRepresentation/PaymentPartnerRepresentation';
import { TransactionSidebarRow } from './TransactionSidebarRow';
import { TransactionWithVehicle } from '../../../../models';

type TransactionDetailsTableProps = {
  transactionWithVehicle: TransactionWithVehicle;
};

export const TransactionSummaryTable = ({
  transactionWithVehicle,
}: TransactionDetailsTableProps) => {
  const { transaction, vehicleWithGroups } = transactionWithVehicle;
  if (!transaction || !vehicleWithGroups) {
    return <></>;
  }

  return (
    <table className="table table-condensed">
      <colgroup>
        <col style={{ width: 150 }} />
        <col />
      </colgroup>
      <tbody>
        <TransactionSidebarRow label="common.transaction.state">
          <TransactionStateLabel state={transaction.state} />
        </TransactionSidebarRow>
        <TransactionSidebarRow label="common.vehicle.vin">
          {vehicleWithGroups.vin}
        </TransactionSidebarRow>
        <TransactionSidebarRow label="transactions.transactionsTable.header.service">
          <ServiceRepresentation serviceType={transaction.serviceType} />
        </TransactionSidebarRow>
        <TransactionSidebarRow label="common.servicePartner">
          <ServicePartnerRepresentation
            servicePartner={transaction.servicePartner}
          />
        </TransactionSidebarRow>
        <TransactionSidebarRow label="common.transaction.createdAt">
          <CustomFormattedDate value={transaction.createdAt} />
        </TransactionSidebarRow>
        <TransactionSidebarRow
          label="common.transaction.lastUpdate"
          show={!!transaction.updatedAt}
        >
          <CustomFormattedDate value={transaction.updatedAt} />
        </TransactionSidebarRow>
        <TransactionSidebarRow
          label="common.transaction.paidAt"
          show={!!transaction.paymentDetails.paidAt}
        >
          <CustomFormattedDate value={transaction.paymentDetails.paidAt} />
        </TransactionSidebarRow>
        <TransactionSidebarRow label="common.paymentMethod">
          <PaymentPartnerRepresentation
            paymentPartner={transaction.paymentDetails.paymentPartner}
            paymentMethod={transaction.paymentDetails.paymentMethod}
          />
        </TransactionSidebarRow>
        <TransactionSidebarRow label="common.transaction.mileage">
          {transaction.mileage}
        </TransactionSidebarRow>
        <TransactionSidebarRow label="common.transaction.id">
          {transaction.id}
        </TransactionSidebarRow>
      </tbody>
    </table>
  );
};

import { ChartData } from './CostsPerServiceOverTimeChart';
import { ServiceType, Transaction } from '../../../../codegen/transactions';
import moment from 'moment/moment';

const FORMAT_MONTH_PATTERN = 'YYYY-MM';
const CHART_FORMAT_MONTH_PATTERN = 'MM/YYYY';

export type CostsPerService = Record<ServiceType, number>;
type CostsPerServicePerMonth = Record<string, CostsPerService>;

const buildEmptyCostsPerService = (): CostsPerService => {
  return {
    [ServiceType.Fueling]: 0,
    [ServiceType.Breakdown]: 0,
    [ServiceType.Parking]: 0,
    [ServiceType.Washing]: 0,
  };
};

const groupCostsPerServicePerMonth = (
  transactions: Transaction[],
): CostsPerServicePerMonth => {
  return transactions.reduce(
    (draftCosts: CostsPerServicePerMonth, transaction: Transaction) => {
      const date = moment(transaction.createdAt);
      const month: string = date.format(FORMAT_MONTH_PATTERN);

      if (draftCosts[month] === undefined) {
        draftCosts[month] = buildEmptyCostsPerService();
      }

      draftCosts[month][transaction.serviceType] += parseFloat(
        transaction.totalAmountGross ?? '0',
      );
      return draftCosts;
    },
    {},
  );
};

const sortAndFillMissingMonths = (months: string[]): string[] => {
  if (months.length === 0) {
    return months;
  }

  const sortedMonths: string[] = months.sort();
  const firstMonth = moment(sortedMonths.at(0));
  const lastMonth = moment(sortedMonths.at(-1));

  const filledMonths: string[] = [];
  for (const month = firstMonth; month <= lastMonth; month.add(1, 'months')) {
    filledMonths.push(month.format(FORMAT_MONTH_PATTERN));
  }
  return filledMonths;
};

export const buildChartData = (transactions: Transaction[]): ChartData => {
  const costsPerServicePerMonth: CostsPerServicePerMonth =
    groupCostsPerServicePerMonth(transactions);

  const months: string[] = Object.keys(costsPerServicePerMonth);

  const filledSortedMonths: string[] = sortAndFillMissingMonths(months);

  return filledSortedMonths.map((month: string) => {
    const costsPerServiceForMonth: CostsPerService =
      costsPerServicePerMonth[month] ?? buildEmptyCostsPerService();
    return {
      label: moment(month).format(CHART_FORMAT_MONTH_PATTERN),
      data: {
        ...costsPerServiceForMonth,
      },
    };
  });
};

import React, { useState } from 'react';
import {
  CustomFormattedDate,
  CustomFormattedMessage,
  TranslationsKeys,
  useCustomIntl,
} from '../../../../i18n/i18n';
import {
  PAYMENT_METHOD_TO_TRANSLATION_KEY,
  VEHICLE_TYPE_TO_TRANSLATION_KEY,
} from '../../../../i18n/enumMappings';
import { MonetaryValue } from '../../../../components/MonetaryValue';
import { TransactionStateLabel } from '../TransactionStateLabel';
import { TransactionsTableToolbar } from './TransactionsTableToolbar';
import {
  getTransactionPage,
  setPage,
  TRANSACTION_TABLE_PAGE_SIZE,
  useTableFilteredTransactions,
} from '../../../../state/transactions/transactionFilterSlice';
import {
  openSidebar,
  setSidebarTransaction,
} from '../../state/transactionsUiSlice';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import { ServiceRepresentation } from '../../../../components/dataRepresentation/ServiceRepresentation';
import { TableColumn } from '../../../../components/table/TableRow';
import { RowData, Table } from '../../../../components/table/Table';
import { Pagination } from '../../../../components/table/Pagination';
import { useVehicles } from '../../../../state/vehicles/useVehicles';
import { TransactionWithVehicle } from '../../../../models';
import { mapTransactionsToRows, TransactionRow } from './mapTransactionsToRows';
import { VehicleRepresentation } from '../../../../components/dataRepresentation/VehicleRepresentation';
import { VehicleGroupsRepresentation } from '../../../../components/dataRepresentation/VehicleGroupsRepresentation';

export const TransactionTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();

  const { filteredTransactions, totalTransactions, isFetching } =
    useTableFilteredTransactions();

  const [highlightedRow, setHighlightedRow] = useState<string | undefined>();

  const { vehicles } = useVehicles();

  const page = useAppSelector(getTransactionPage);
  const transactionsVehicleMap: Map<string, TransactionWithVehicle> = new Map();

  const handleTransactionClicked = (id: string) => {
    dispatch(setSidebarTransaction(transactionsVehicleMap.get(id)!));
    dispatch(openSidebar());
  };

  const handlePageChanged = (pageNumber: number) => {
    dispatch(setPage(pageNumber));
  };

  const getLabel = (id: TranslationsKeys) => {
    return intl.formatMessage({
      id,
    });
  };

  const columns: TableColumn<TransactionRow>[] = [
    {
      id: 'vehicleName',
      label: getLabel('transactions.transactionsTable.header.vehicle'),
      renderer: (transaction: TransactionRow) => (
        <VehicleRepresentation
          vehicleType={transaction.vehicleType}
          vehicleName={transaction.vehicleName}
        />
      ),
    },
    {
      id: 'vehicleType',
      label: getLabel('transactions.transactionsTable.header.type'),
      renderer: (transaction: TransactionRow) => {
        if (!transaction.vehicleType) {
          return <></>;
        }
        return (
          <CustomFormattedMessage
            id={VEHICLE_TYPE_TO_TRANSLATION_KEY[transaction.vehicleType]}
          />
        );
      },
    },
    {
      id: 'vehicleGroups',
      label: getLabel('transactions.transactionsTable.header.groups'),
      renderer: (transaction: TransactionRow) => (
        <VehicleGroupsRepresentation
          vehicleGroups={transaction.vehicleGroups}
        />
      ),
    },
    {
      id: 'service',
      label: getLabel('transactions.transactionsTable.header.service'),
      renderer: (transaction: TransactionRow) => (
        <ServiceRepresentation serviceType={transaction.service} />
      ),
    },
    {
      id: 'createdAt',
      label: getLabel('transactions.transactionsTable.header.createdAt'),
      renderer: (transaction: TransactionRow) => (
        <CustomFormattedDate value={transaction.createdAt} />
      ),
    },
    {
      id: 'paymentMethod',
      label: getLabel('transactions.transactionsTable.header.paymentMethod'),
      renderer: (transaction: TransactionRow) => (
        <CustomFormattedMessage
          id={PAYMENT_METHOD_TO_TRANSLATION_KEY[transaction.paymentMethod]}
        />
      ),
    },
    {
      id: 'totalPriceGross',
      label: getLabel('common.transaction.totalPriceGross'),
      renderer: (transaction: TransactionRow) => (
        <span className={'text-medium'}>
          {!!transaction.totalPriceGross && !!transaction.currency && (
            <MonetaryValue
              amount={transaction.totalPriceGross}
              currency={transaction.currency}
            />
          )}
        </span>
      ),
    },
    {
      id: 'status',
      label: getLabel('transactions.transactionsTable.header.status'),
      renderer: (transaction: TransactionRow) => (
        <TransactionStateLabel state={transaction.status} />
      ),
    },
  ];

  const rowData: RowData<TransactionRow>[] = mapTransactionsToRows(
    filteredTransactions,
    vehicles,
    transactionsVehicleMap,
  );

  const numberOfPages = Math.ceil(
    totalTransactions / TRANSACTION_TABLE_PAGE_SIZE,
  );

  return (
    <div className="display-flex flex-column height-100pct">
      <TransactionsTableToolbar />
      <div
        className="table-responsive overflow-y-auto"
        data-testid="transactions-body-transactions-table"
      >
        <Table
          columns={columns}
          rowData={rowData}
          highlightedRow={highlightedRow}
          onRowClicked={(key) => {
            setHighlightedRow(key);
            handleTransactionClicked(key);
          }}
          isLoading={isFetching}
        />
      </div>
      <Pagination
        currentPage={page}
        numberOfPages={numberOfPages}
        onPageChanged={handlePageChanged}
      />
    </div>
  );
};

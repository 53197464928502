import { Subscription } from './baseQuery';
import { parse } from 'graphql';
import { USED_TRANSACTION_FIELDS } from './queries';

export const onTransactionUpdated: Subscription = {
  definition: parse(`
    subscription OnTransactionUpdated($account_id: String!) {
      onTransactionUpdated(account_id: $account_id) {
          ${USED_TRANSACTION_FIELDS}
      }
    }
  `),
};

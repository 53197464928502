import { bookingsApiSlice as api } from '../state/bookings/bookingsSlice';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVehicles: build.query<GetVehiclesApiResponse, GetVehiclesApiArg>({
      query: () => ({ url: `/vehicles` }),
    }),
    getVehicleById: build.query<
      GetVehicleByIdApiResponse,
      GetVehicleByIdApiArg
    >({
      query: (queryArg) => ({ url: `/vehicles/${queryArg.vehicleId}` }),
    }),
    updateServiceActivations: build.mutation<
      UpdateServiceActivationsApiResponse,
      UpdateServiceActivationsApiArg
    >({
      query: (queryArg) => ({
        url: `/vehicles/${queryArg.vehicleId}`,
        method: 'PATCH',
        body: queryArg.updateServiceActivationsRequest,
      }),
    }),
    addPaymentMethod: build.mutation<
      AddPaymentMethodApiResponse,
      AddPaymentMethodApiArg
    >({
      query: (queryArg) => ({
        url: `/vehicles/${queryArg.vehicleId}/paymentMethods`,
        method: 'POST',
        body: queryArg.addPaymentMethodRequest,
      }),
    }),
    removePaymentMethod: build.mutation<
      RemovePaymentMethodApiResponse,
      RemovePaymentMethodApiArg
    >({
      query: (queryArg) => ({
        url: `/vehicles/${queryArg.vehicleId}/paymentMethods/${queryArg.paymentMethodId}`,
        method: 'DELETE',
      }),
    }),
    getTransactions: build.query<
      GetTransactionsApiResponse,
      GetTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/transactions`,
        params: {
          pageSize: queryArg.pageSize,
          pageNumber: queryArg.pageNumber,
          vehicleId: queryArg.vehicleId,
          vehicleGroupId: queryArg.vehicleGroupId,
          serviceType: queryArg.serviceType,
          currency: queryArg.currency,
          fromCreatedAt: queryArg.fromCreatedAt,
          toCreatedAt: queryArg.toCreatedAt,
        },
      }),
    }),
    getTransactionById: build.query<
      GetTransactionByIdApiResponse,
      GetTransactionByIdApiArg
    >({
      query: (queryArg) => ({ url: `/transactions/${queryArg.transactionId}` }),
    }),
    postFuelingTransactions: build.mutation<
      PostFuelingTransactionsApiResponse,
      PostFuelingTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/fueling/transactions`,
        method: 'POST',
        body: queryArg.startFuelingTransactionRequest,
      }),
    }),
    patchFuelingTransaction: build.mutation<
      PatchFuelingTransactionApiResponse,
      PatchFuelingTransactionApiArg
    >({
      query: (queryArg) => ({
        url: `/fueling/transactions/${queryArg.transactionId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    listBookings: build.query<ListBookingsApiResponse, ListBookingsApiArg>({
      query: (queryArg) => ({
        url: `/bookings`,
        params: {
          pageSize: queryArg.pageSize,
          pageNumber: queryArg.pageNumber,
          vehicleIds: queryArg.vehicleIds,
          vehicleGroupIds: queryArg.vehicleGroupIds,
          arrivalBefore: queryArg.arrivalBefore,
          arrivalAfter: queryArg.arrivalAfter,
          serviceType: queryArg.serviceType,
          status: queryArg.status,
        },
      }),
    }),
    getBooking: build.query<GetBookingApiResponse, GetBookingApiArg>({
      query: (queryArg) => ({ url: `/bookings/${queryArg.bookingId}` }),
    }),
    createParkingBooking: build.mutation<
      CreateParkingBookingApiResponse,
      CreateParkingBookingApiArg
    >({
      query: (queryArg) => ({
        url: `/parking/bookings`,
        method: 'POST',
        body: queryArg.bookParkingLotRequest,
      }),
    }),
    cancelParkingBooking: build.mutation<
      CancelParkingBookingApiResponse,
      CancelParkingBookingApiArg
    >({
      query: (queryArg) => ({
        url: `/parking/bookings/${queryArg.bookingId}`,
        method: 'PATCH',
        body: queryArg.cancelParkingLotRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetVehiclesApiResponse = /** status 200 OK */ VehiclesList;
export type GetVehiclesApiArg = void;
export type GetVehicleByIdApiResponse = /** status 200 OK */ Vehicle;
export type GetVehicleByIdApiArg = {
  /** Vehicle ID in context of RIO asset management */
  vehicleId: string;
};
export type UpdateServiceActivationsApiResponse = /** status 200 OK */ Vehicle;
export type UpdateServiceActivationsApiArg = {
  /** Vehicle ID in context of RIO asset management */
  vehicleId: string;
  updateServiceActivationsRequest: UpdateServiceActivationsRequest;
};
export type AddPaymentMethodApiResponse =
  /** status 201 CREATED */ PaymentMethod;
export type AddPaymentMethodApiArg = {
  /** Vehicle ID in context of RIO asset management */
  vehicleId: string;
  addPaymentMethodRequest: AddPaymentMethodRequest;
};
export type RemovePaymentMethodApiResponse = /** status 204 NO CONTENT */ void;
export type RemovePaymentMethodApiArg = {
  /** Vehicle ID in context of RIO asset management */
  vehicleId: string;
  /** Payment method ID in context of a vehicle */
  paymentMethodId: string;
};
export type GetTransactionsApiResponse = /** status 200 OK */ TransactionsList;
export type GetTransactionsApiArg = {
  pageSize: number;
  pageNumber: number;
  vehicleId?: string[];
  vehicleGroupId?: string[];
  serviceType?: ServiceType[];
  currency?: Currency[];
  fromCreatedAt?: string;
  toCreatedAt?: string;
};
export type GetTransactionByIdApiResponse = /** status 200 OK */ Transaction;
export type GetTransactionByIdApiArg = {
  transactionId: string;
};
export type PostFuelingTransactionsApiResponse =
  /** status 201 Created */ Transaction;
export type PostFuelingTransactionsApiArg = {
  startFuelingTransactionRequest: StartFuelingTransactionRequest;
};
export type PatchFuelingTransactionApiResponse =
  /** status 200 OK */ Transaction;
export type PatchFuelingTransactionApiArg = {
  transactionId: string;
  body: CancelFuelingTransactionRequest;
};
export type ListBookingsApiResponse = /** status 200 OK */ BookingList;
export type ListBookingsApiArg = {
  pageSize: number;
  pageNumber: number;
  vehicleIds?: string[];
  vehicleGroupIds?: string[];
  arrivalBefore?: string;
  arrivalAfter?: string;
  serviceType?: ServiceType[];
  status?: BookingStatus[];
};
export type GetBookingApiResponse =
  /** status 200 The requested booking */ Booking;
export type GetBookingApiArg = {
  bookingId: string;
};
export type CreateParkingBookingApiResponse = /** status 201 CREATED */ Booking;
export type CreateParkingBookingApiArg = {
  bookParkingLotRequest: BookParkingLotRequest;
};
export type CancelParkingBookingApiResponse =
  /** status 200 The booking was updated successfully */ Booking;
export type CancelParkingBookingApiArg = {
  bookingId: string;
  cancelParkingLotRequest: CancelParkingLotRequest;
};
export type PaymentMethodExpiryDate = string;
export type PaymentMethod = {
  id: string;
  type: PaymentMethodType;
  paymentPartner: PaymentPartnerType;
  lastFourDigits: string;
  expiry: PaymentMethodExpiryDate;
};
export type PaymentMethodAssignment = {
  /** Reference to `vehicle.paymentMethods[].id`. */
  paymentMethodId: string;
};
export type ServiceActivation = {
  enabled: boolean;
  serviceType: ServiceType;
  paymentMethodAssignments: PaymentMethodAssignment[];
};
export type Vehicle = {
  id: string;
  name: string;
  vin: string;
  type: VehicleType;
  /** Indicates that the vehicle was not activated for SimplePay yet. */
  enabled: boolean;
  group_ids: string[];
  brand: string;
  paymentMethods: PaymentMethod[];
  serviceActivations: ServiceActivation[];
};
export type VehiclesList = {
  items: Vehicle[];
};
export type ErrorResponse = {
  message: string;
  errorCode: ErrorCode;
  fields: string[];
};
export type UpdateServiceActivationsRequest = {
  serviceActivations: ServiceActivation[];
};
export type AddPaymentMethodRequest = {
  type: PaymentMethodType;
  paymentPartner: PaymentPartnerType;
  /** Service Card Number */
  cardNumber: string;
  expiry: PaymentMethodExpiryDate;
  /** Token representing the user at desired paymentPartner. Currently only required if paymentPartner equals UTA_PAYMENT */
  paymentPartnerUserToken?: string;
};
export type PageResponse = {
  totalPages: number;
  totalItems: number;
};
export type PaymentDetails = {
  paymentMethod: PaymentMethodType;
  paymentPartner: PaymentPartnerType;
  paidAt?: string;
};
export type Currency = string;
export type Address = {
  street: string;
  zipCode: string;
  city: string;
  state?: string;
  /** A 2-digit country code, conforming to ISO 3166 Alpha-2 notation */
  country: string;
};
export type Poi = {
  /** Reference to the Point of Interest from SimplePay's PoiService */
  id: string;
  name: string;
  brand?: string;
  address: Address;
};
export type MonetaryValue = string;
export type TransactionItem = {
  description: string;
  quantity: string;
  unit: TransactionItemUnit;
  itemPriceNet: MonetaryValue;
  itemPriceGross: MonetaryValue;
  sumNet: MonetaryValue;
  sumGross: MonetaryValue;
  vat: string;
};
export type Transaction = {
  id: string;
  /** Vehicle ID in context of RIO asset management */
  vehicleId: string;
  /** Mileage of the assosiated vehicle */
  mileage: number;
  paymentDetails: PaymentDetails;
  currency?: Currency;
  serviceType: ServiceType;
  servicePartner: ServicePartner;
  poi?: Poi;
  pumpNumber?: number;
  invoiceNumber?: string;
  totalAmountGross?: MonetaryValue;
  totalAmountNet?: MonetaryValue;
  items: TransactionItem[];
  state: TransactionState;
  cancelationState?: TransactionCancelationState;
  errorCode?: TransactionErrorCode;
  /** Timestamp when transaction was created. */
  createdAt: string;
  /** Timestamp when transaction was updated. */
  updatedAt?: string;
};
export type TransactionsList = PageResponse & {
  items: Transaction[];
};
export type GeoCoordinates = {
  latitude: string;
  longitude: string;
};
export type StartFuelingTransactionRequest = {
  /** Vehicle ID in context of RIO asset management */
  vehicleId: string;
  driverId?: string;
  /** Current mileage of the vehicle */
  mileage: string;
  /** Current position of the vehicle */
  vehicleLocation: GeoCoordinates;
  /** ID of a POI to call requests to SimplePay PoiService */
  poiId: string;
  pumpNumber: number;
};
export type CancelFuelingTransactionRequest = {
  status: Status;
};
export type Vin = string;
export type BookParkingLotRequest = {
  /** ID of the location to book a parking for. */
  locationId: string;
  vehicleInfo: {
    /** Asset ID of the vehicle to book the parking spot for. */
    vehicleId: string;
  };
  /** Omitting the trailerInfo empty indicates that the truck does not have a trailer. */
  trailerInfo?: {
    licensePlate: string;
    vin?: Vin;
  };
  /** Arrival time in UTC. Needs to be before `departureTime`. Needs to be in the future for new Bookings. */
  arrivalTime: string;
  /** Departure time in UTC. Needs to be after `arrivalTime`. Needs to be in the future for new Bookings. */
  departureTime: string;
  /** Full name of the driver who will park the vehicle. IDs will usually be checked upon arrival. */
  driverName: string;
  /** Custom reference that can be provided by user to identify bookings */
  bookingReference?: string;
  /** Language in which the entry instructions should be given. Formatted as per ISO 639-1.
    If omitted, instructions will be returned in English.
     */
  instructionLanguage?: InstructionLanguage;
};
export type MonetaryAmount = {
  value: MonetaryValue;
  currency: Currency;
};
export type Booking = BookParkingLotRequest & {
  id: string;
  status: BookingStatus;
  /** RIO user ID ('sub' field of access token) of the user who created the booking */
  createdBy: string;
  serviceType: ServiceType;
  /** ID of the payment method that was used for booking */
  paymentMethodId: string;
  estimatedPrice: MonetaryAmount;
  /** Entry instructions as transmitted from the service provider */
  entryInstructions: {
    instructions?: string;
    entryCode?: string;
    reportAt?: string;
    entryLink?: string;
    locationReservationNumber?: string;
    emergencyPhone?: string;
  };
};
export type BookingList = PageResponse & {
  items: Booking[];
};
export type CancelParkingLotRequest = {
  status: Status;
};
export enum VehicleType {
  Truck = 'TRUCK',
  Bus = 'BUS',
  Van = 'VAN',
  Trailer = 'TRAILER',
  Car = 'CAR',
}
export enum PaymentMethodType {
  FuelCard = 'FUEL_CARD',
}
export enum PaymentPartnerType {
  MockFuelingPayment = 'MOCK_FUELING_PAYMENT',
  UtaPayment = 'UTA_PAYMENT',
}
export enum ServiceType {
  Fueling = 'FUELING',
  Breakdown = 'BREAKDOWN',
  Parking = 'PARKING',
  Washing = 'WASHING',
}
export enum ErrorCode {
  InvalidPathParameters = 'INVALID_PATH_PARAMETERS',
  InvalidQueryParameters = 'INVALID_QUERY_PARAMETERS',
  InvalidPayload = 'INVALID_PAYLOAD',
  InvalidFuelCard = 'INVALID_FUEL_CARD',
  InvalidStatusChange = 'INVALID_STATUS_CHANGE',
  CancelationNotAllowedTransactionAlreadyCanceled = 'CANCELATION_NOT_ALLOWED_TRANSACTION_ALREADY_CANCELED',
  CancelationNotAllowedTransactionNotStarted = 'CANCELATION_NOT_ALLOWED_TRANSACTION_NOT_STARTED',
  CancelationNotAllowedTransactionTransactionFinished = 'CANCELATION_NOT_ALLOWED_TRANSACTION_TRANSACTION_FINISHED',
  PoiNotFound = 'POI_NOT_FOUND',
  OnlinePaymentNotSupported = 'ONLINE_PAYMENT_NOT_SUPPORTED',
  PaymentMethodActive = 'PAYMENT_METHOD_ACTIVE',
  VehicleNotFound = 'VEHICLE_NOT_FOUND',
  VehicleServiceNotActivated = 'VEHICLE_SERVICE_NOT_ACTIVATED',
  PaymentMethodNotFound = 'PAYMENT_METHOD_NOT_FOUND',
  UnknownError = 'UNKNOWN_ERROR',
}
export enum ServicePartner {
  MockFuelingPartner = 'MOCK_FUELING_PARTNER',
  MockBreakdownPartner = 'MOCK_BREAKDOWN_PARTNER',
  MockRoadsidePartner = 'MOCK_ROADSIDE_PARTNER',
  Mobile24 = 'MOBILE_24',
  Travis = 'TRAVIS',
}
export enum TransactionItemUnit {
  Liter = 'LITER',
  Kg = 'KG',
}
export enum TransactionState {
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  Delivered = 'DELIVERED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Canceled = 'CANCELED',
}
export enum TransactionCancelationState {
  Triggered = 'TRIGGERED',
  Requested = 'REQUESTED',
  NotPossible = 'NOT_POSSIBLE',
  Failed = 'FAILED',
  Successful = 'SUCCESSFUL',
}
export enum TransactionErrorCode {
  SelectionNotAvailable = 'SELECTION_NOT_AVAILABLE',
  PreAuthorizationFailed = 'PRE_AUTHORIZATION_FAILED',
  TransactionFailed = 'TRANSACTION_FAILED',
  TransactionNotPossible = 'TRANSACTION_NOT_POSSIBLE',
  InvalidPaymentMethod = 'INVALID_PAYMENT_METHOD',
  UnknownError = 'UNKNOWN_ERROR',
}
export enum Status {
  Canceled = 'CANCELED',
}
export enum InstructionLanguage {
  En = 'en',
  Fr = 'fr',
  Ru = 'ru',
  Da = 'da',
  Es = 'es',
  Cs = 'cs',
  It = 'it',
  Hu = 'hu',
  De = 'de',
  Ro = 'ro',
  Pl = 'pl',
  Nl = 'nl',
  Pt = 'pt',
  Uk = 'uk',
}
export enum BookingStatus {
  Planned = 'PLANNED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
}
export const {
  useGetVehiclesQuery,
  useGetVehicleByIdQuery,
  useUpdateServiceActivationsMutation,
  useAddPaymentMethodMutation,
  useRemovePaymentMethodMutation,
  useGetTransactionsQuery,
  useGetTransactionByIdQuery,
  usePostFuelingTransactionsMutation,
  usePatchFuelingTransactionMutation,
  useListBookingsQuery,
  useGetBookingQuery,
  useCreateParkingBookingMutation,
  useCancelParkingBookingMutation,
} = injectedRtkApi;

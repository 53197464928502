import de from './de-DE.json';
import en from './en-GB.json';
import * as React from 'react';
import {
  FormattedDate,
  FormattedMessage,
  IntlFormatters,
  useIntl,
} from 'react-intl';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import { monetaryValueOptions } from '../components/MonetaryValue';

export const DEFAULT_LOCALE = 'en-GB';

export type Translations = typeof en;
export type TranslationsKeys = keyof Translations;
export type TranslationsLabelValues = Record<
  string,
  PrimitiveType | FormatXMLElementFn<string, string>
>;

type TranslationsMap = {
  [key: string]: Translations;
};

const supportedTranslations: TranslationsMap = {
  'de-DE': de,
  'en-GB': en,
};

export const getMessagesForLocale = (
  locale: string,
): Record<string, string> => {
  const selected = supportedTranslations[locale];
  return selected ? selected : supportedTranslations[DEFAULT_LOCALE];
};

type CustomFormattedMessageProps = Record<
  string,
  | string
  | number
  | boolean
  | object
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactFragment
  | React.ReactPortal
  | Date
  | FormatXMLElementFn<React.ReactNode, React.ReactNode>
  | TranslationsLabelValues
  | null
  | undefined
> & {
  id: TranslationsKeys;
};

export const CustomFormattedMessage = ({
  id,
  ...rest
}: CustomFormattedMessageProps) => {
  return id ? (
    <FormattedMessage
      id={id}
      {...rest}
    />
  ) : (
    <></>
  );
};

type FormatMessageArgs = Parameters<IntlFormatters['formatMessage']>;

export const useCustomIntl = () => {
  const intl = useIntl();

  const customFormatMessage = (
    descriptor: FormatMessageArgs[0] & {
      id?: TranslationsKeys;
    },
    values?: TranslationsLabelValues,
    options?: FormatMessageArgs[2],
  ): string => {
    return intl.formatMessage(descriptor, values, options) as string;
  };

  const formatMonetaryAmount = (
    amount: number,
    currency: string,
    maximumFractionDigits: number = 2,
  ) => {
    return intl.formatNumber(amount, {
      ...monetaryValueOptions(currency, maximumFractionDigits),
    });
  };

  return {
    ...intl,
    formatMessage: customFormatMessage,
    formatMonetaryAmount,
  };
};

export const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const CustomFormattedDate = ({ value }: { value?: number | string }) => {
  return (
    <FormattedDate
      value={value}
      {...dateFormat}
    />
  );
};
